import VisitIcon from '@mui/icons-material/Visibility';
import VisitShow from "./VisitShow";
import VisitList from "./VisitList";

const resource = {
  list: VisitList,
  show: VisitShow,
  icon: VisitIcon,
};

export default resource;
