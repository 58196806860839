import {useRecordContext} from "react-admin";
import { Link } from 'react-router-dom';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import Check from '@mui/icons-material/Check';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import SearchIcon from '@mui/icons-material/Search';
import QuestionMark from '@mui/icons-material/QuestionMark';
import {Tooltip} from '@mui/material'
import {stringify} from "query-string";


export const CondyearDiagnosisClaimStatusIcon = (props) => {
  const record = useRecordContext();
  if (!record) return null;
  if (!props.conditionyear) {
    console.error("Did not specify the conditionyear prop")
  }
  let value = record[props.source]

  let returnElement;
  let linkTo = {
    pathname: '/claim',
    search: stringify({
      filter: JSON.stringify({
        'patient_id': record.patient_id,
        "condition_years#condition_year_id@_eq": props.conditionyear,
        'diagnoses#diag_code@_eq': record['diag_code']
      }),
      sort: 'max_service_date',
      order: 'DESC'
    }),
  }

  switch (value){
    case 40:
      returnElement = <Link to={linkTo}><Tooltip title='Accepted'>
        <Check color='success' fontSize='small'/>
      </Tooltip></Link>;
      break;
    case 30:
      returnElement = <Link to={linkTo}><Tooltip title='Denied'>
        <DoDisturbIcon color='error' fontSize='small'/>
      </Tooltip></Link>;
      break;
    case 20:
      returnElement = <Link to={linkTo}><Tooltip title='Pending'>
        <HourglassBottomIcon color='warning'/></Tooltip></Link>;
      break;
    case 15:
    case 16:
    case 17:
    case 18:
      returnElement = <Tooltip title='Question'>
        <QuestionMark color='primary' fontSize='small'/>
      </Tooltip>;
      break;
    case 10:
      returnElement = <Tooltip title='Open'>
        <SearchIcon color='primary' fontSize='small'/>
      </Tooltip>;
      break;
    default:
      return <></>;
  }
  return returnElement
}
