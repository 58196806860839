import {gql} from "@apollo/client";

export const startAudit = async (apolloClient, audit_id) => {
  const mutationOptions = {
    mutation: gql`
          mutation startAudit($id: Int!) {
            start_audit(audit_id: $id) {
              message
            }
          }
        `,
    variables: {
      id: audit_id
    }
  }
  return apolloClient.mutate(mutationOptions);
};

export const addClaimsToAudit = async (apolloClient, audit_id, claim_ids) => {
  const mutationOptions = {
    mutation: gql`
          mutation addClaimsToAudit($id: Int!, $claim_ids: [String]!) {
            add_claims_to_audit(audit_id: $id, claim_ids: $claim_ids) {
              message
            }
          }
        `,
    variables: {
      id: audit_id,
      claim_ids: claim_ids
    }
  }
  return apolloClient.mutate(mutationOptions);
};

export const delClaimsFromAudit = async (apolloClient, audit_id, audit_claim_ids, related) => {
  const mutationOptions = {
    mutation: gql`
          mutation delClaimsFromAudit($id: Int!, $audit_claim_ids: [Int]!, $related: Boolean!) {
            del_claims_from_audit(audit_id: $id, audit_claim_ids: $audit_claim_ids, related: $related) {
              message
            }
          }
        `,
    variables: {
      id: audit_id,
      audit_claim_ids: audit_claim_ids,
      related: related
    }
  }
  return apolloClient.mutate(mutationOptions);
};
