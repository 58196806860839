import {useRecordContext} from "react-admin";
import CheckIcon from "@mui/icons-material/Check";
import {stringify} from "query-string";
import {Link} from "react-router-dom";

const AcceptedConditionsYearCheckIconField = ({source, conditionYear}) => {
  const record = useRecordContext();
  const historicYears = record[source];
  let icon = null;
  // not filtering conditions by model right now. Could be maybe mixing conditions from two different models?
  // This is because ra-data-hasura does not seem to have a way to make an AND query.

  // Jumps to a filtered list of claims for the patient, condition year and any of the diagnoses for that condition
  let linkTo = {
    pathname: '/claim',
    search: stringify({
      filter: JSON.stringify({
        'patient_id': record?.patient_id,
        "condition_years#condition_year_id@_eq": conditionYear,
        "diagnoses#diagnosis_condition#condition_id@_eq": record?.condition_id,
      }),
      sort: 'max_service_date',
      order: 'DESC'
    }),
  }

  if(historicYears.includes(conditionYear)) {
    icon = <Link
      to={linkTo}>
      <CheckIcon color='success' fontSize='small'/>
    </Link>
  }
  return icon;
}

export default AcceptedConditionsYearCheckIconField;
