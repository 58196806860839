import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { VITE_COGNITO_APP_CLIENT_ID, VITE_COGNITO_USERPOOL_ID } from "../config";

const userPool = new CognitoUserPool({
  UserPoolId: VITE_COGNITO_USERPOOL_ID,
  ClientId: VITE_COGNITO_APP_CLIENT_ID,
});

export async function getIdToken () {
  try {
    const currentUser = userPool.getCurrentUser();
    if (currentUser) {
      const session = await new Promise((resolve, reject) => {
        currentUser.getSession((err, session) => {
          if (err) {
            reject(err);
          } else {
            resolve(session);
          }
        });
      });
      return session.getIdToken();
    }
  } catch (e) {
    console.log('@sapiensUser.getIdToken. Error when trying to get session/idToken:',e);
  }
};

export async function getPayload () {
  try {
    const idToken = await getIdToken();
    return JSON.parse(idToken['payload']['https://hasura.io/jwt/claims']);
  } catch (e) {
    console.log({e});
  }
};

export async function getUserId () {
  try {
    const payload = await getPayload();
    return payload['x-hasura-user-id'];
  } catch (e) {
    console.log({e});
  }
};

export async function getQuicksightId () {
  try {
    const payload = await getPayload();
    return payload['x-hasura-quicksight-id'];
  } catch (e) {
    console.log({e});
  }
};
