import * as React from 'react';
import {
  Create,
  SaveButton,
  Toolbar, TopToolbar,
  useNotify
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import {BackToReconciliationReportButton} from "./BackToReconciliationReportButton";
import ReconciliationReportQueryForm from "./ReconciliationReportQueryForm";



const ReconciliationReportQueryToolbar = () => {
  const {  setValue } = useFormContext();
  const notify = useNotify();
  return (
    <Toolbar>
      <SaveButton label="Save" />
      <SaveButton
        label="Save and add another"
        mutationOptions={{
          onSuccess: () => {
            notify('Element created, ready to add another');
            setValue("@@ra-many/reconciliation_report_query/reconciliation_report_query_diagnosis/reconciliation_report_query_id.0.reconciliation_report_query_diagnosis", [{diagnosis_id: "", diagnosis_description:""}])
          }}
        }
        type="button"
        variant="text"
      />
    </Toolbar>
  );
};

const ReconciliationReportQueryCreateActions = () => {
  return (
    <TopToolbar>
      <BackToReconciliationReportButton />
    </TopToolbar>
  );
}



export const ReconciliationReportQueryCreate = () => {
  const location = useLocation();

  let redirect;
  if (location && location.state && 'redirectBack' in location.state) {
    redirect = location.state['redirectBack'];
  } else {
    redirect = `/reconciliation_report/`;
  }
  return (
    <Create
     redirect={redirect}
     actions={<ReconciliationReportQueryCreateActions />}
    >
      <ReconciliationReportQueryForm
        toolbar={<ReconciliationReportQueryToolbar />}
      />
    </Create>
  );
}


export default ReconciliationReportQueryCreate;
