import * as React from 'react';
import { ReferenceField, FunctionField } from 'react-admin';
import NotesIcon from '@mui/icons-material/Notes';


const NoteAvailableReferenceField = (props) => (
  <ReferenceField
    source="patient_id"
    reference="patient"
    link="show"
    {...props}
  >
    <FunctionField label="Patient note" render={record => {
      if(record?.note) {
        return <NotesIcon color="primary" />
      }
    }} />
  </ReferenceField>
);

export default NoteAvailableReferenceField;
