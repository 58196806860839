import DescriptionIcon from '@mui/icons-material/Description';
import DataFileList from "./DataFileList";
import DataFileCreate from "./DataFileCreate";

const resource = {
  list: DataFileList,
  create: DataFileCreate,
  icon: DescriptionIcon,
};

export default resource;