import { Edit, SaveButton, SimpleForm, Toolbar } from "react-admin";
import { RichTextInput } from 'ra-input-rich-text';
import PatientTitle from "./PatientTitle";


const PatientEditToolbar = () => {
  return (
    <Toolbar>
      <SaveButton/>
    </Toolbar>
  )
}

export const PatientEdit = () => {
  return (
    <Edit title={<PatientTitle/>} redirect='show'>
      <SimpleForm toolbar={<PatientEditToolbar/>}>
        <RichTextInput
          source="note"
          multiline
          fullWidth
        />
      </SimpleForm>
    </Edit>
  )
}
export default PatientEdit;
