import GatekeeperIcon from '@mui/icons-material/LocalHospital';
import GatekeeperList from "./GatekeeperList";
import GatekeeperEdit from "./GatekeeperEdit";
import GatekeeperShow from "./GatekeeperShow";

const resource = {
  list: GatekeeperList,
  edit: GatekeeperEdit,
  show: GatekeeperShow,
  icon: GatekeeperIcon,
};

export default resource;
