import * as React from 'react';
import { ReferenceField, TextField } from 'react-admin';


const ProviderReferenceField = (props) => {
  const {label, source, reference, ...rest} = props;
  return <ReferenceField
    label={label}
    source={source}
    reference={reference}
    {...rest}
  >
    <TextField source="name" />
  </ReferenceField>
}

ProviderReferenceField.defaultProps = {
  source: 'serv_prov',
  label:'Provider',
  reference: 'provider',
};

export default ProviderReferenceField;
