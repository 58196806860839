import {
  Datagrid,
  DateField,
  List,
  TextField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  ReferenceField,
  TextInput,
  NullableBooleanInput,
  DateInput,
  NumberInput,
} from "react-admin";
import PatientInput from '../patient/PatientInput';
import PatientNameReferenceField from '../patient/PatientNameReferenceField';
import GatekeeperInput from '../providerpractitioner/GatekeeperInput';

const billingClaimCmsClaimFilters = [
  <TextInput label="Id" source="id" />,
  <NullableBooleanInput label="Has claims" source="has_matched_claims" />,
  <TextInput label="Claim" source="claim_ids@_contains" />,
  <NullableBooleanInput label="Has billing claims" source="has_matched_billing_claims" />,
  <TextInput label="Billing claim" source="billing_claim_ids@_contains" />,
  <DateInput label="From service date" source="service_date@_gte" />,
  <DateInput label="To service date" source="service_date@_lte" />,
  <DateInput label="From min service date" source="min_service_date@_gte" />,
  <DateInput label="To min service date" source="min_service_date@_lte" />,
  <DateInput label="From max service date" source="max_service_date@_gte" />,
  <DateInput label="To max service date" source="max_service_date@_lte" />,
  <NumberInput label="From days" source="billing_claim_days@_gte" />,
  <NumberInput label="To days" source="billing_claim_days@_lte" />,
  <TextInput label="Primary payer" source="primary_payer" />,
  <PatientInput label="Patient" source="patient_id" name="patient_id"/>,
  <GatekeeperInput label="Gatekeeper" source="gk_id" />,
  <TextInput label="Npi" source="npi" />,
  <NullableBooleanInput label="Has unmatched claim diagnosis" source="has_unmatched_claim_diagnosis" />,
  <NullableBooleanInput label="Has unmatched billing claim diagnosis" source="has_unmatched_billing_claim_diagnosis" />,
  <NullableBooleanInput label="Has unmatched claim service" source="has_unmatched_claim_service" />,
  <NullableBooleanInput label="Has unmatched billing claim service" source="has_unmatched_billing_claim_service" />,
  <NullableBooleanInput label="Has unmatched claim value" source="has_unmatched_claim_value" />,
];

export const BillingClaimCmsClaimList = () => (
  <List filters={billingClaimCmsClaimFilters} sort={{ field: "id", order: "DESC" }} >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <ReferenceArrayField reference="claim" source="claim_ids" >
        <SingleFieldList linkType={false} empty={<div>N/A</div>}>
            <ChipField source="claim_nbr" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField reference="billing_claim" source="billing_claim_ids" >
        <SingleFieldList linkType={false} empty={<div>N/A</div>}>
            <ChipField source="claim_id" />
        </SingleFieldList>
      </ReferenceArrayField>
      <DateField source="service_date" />
      <DateField source="min_service_date" />
      <DateField source="max_service_date" />
      <TextField source="billing_claim_days" label="Days" />
      <TextField source="primary_payer" />
      <PatientNameReferenceField link={false} />
      <ReferenceField
        source="gk_id"
        reference="gatekeeper"
        label="Gatekeeper"
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="npi" />
      <TextField source="total_unmatched_claim_diagnosis"/>
      <TextField source="total_unmatched_billing_claim_diagnosis"/>
      <TextField source="total_unmatched_claim_service"/>
      <TextField source="total_unmatched_billing_claim_service"/>
    </Datagrid>
  </List>
);

export default BillingClaimCmsClaimList;
