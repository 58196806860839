import {
  ChipField,
  Datagrid,
  DateField, Link, Pagination,
  ReferenceField,
  ReferenceManyField,
  SimpleShowLayout, SingleFieldList,
  TextField,
  useRecordContext
} from "react-admin";
import {SapiensFieldsRow} from "../components/SapiensFieldsRow";
import PatientNameReferenceField from "../patient/PatientNameReferenceField";
import CMSStatusField from "../claim/CMSStatusField";
import ProviderReferenceField from "../provider/ProviderReferenceField";
import ServiceOriginField from "../claim/ServiceOriginField";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import CheckIcon from "@mui/icons-material/Check";
import {Breadcrumbs, Typography} from "@mui/material";
import {AuditDiagnosisResultChoices} from "./constants";

const RelatedClaimField = ({current, ...rest}) => {
  const record = useRecordContext();
  let icon = null;
  if (record.claim_id === current){
    if (record.status === 'audited'){
      icon = <BookmarkAddedIcon/>
    } else {
      icon = <BookmarkIcon/>;
    }
  } else if (record.status === 'audited'){
    icon = <CheckIcon/>
  }
  let color;
  switch (record.claim.mao_flag){
    case 'A':
      color = 'success';
      break;
    case 'D':
      color = 'error';
      break;
    default:
      color = 'warning';
      break
  }
  return (<ChipField record={ record.claim } variant="outlined" icon={icon} color={color} source='claim_nbr'/>);
}

export const ClaimShow = ({relatedClaimsLink, ...rest}) => {
  const auditClaimRecord = useRecordContext();
  if (!auditClaimRecord || !auditClaimRecord.claim) return null;
  return (
    <SimpleShowLayout record={auditClaimRecord.claim}>
      <SapiensFieldsRow>
        <ReferenceField reference='claim' source='id' link='show'>
          <TextField source="claim_nbr"/>
        </ReferenceField>
        <PatientNameReferenceField/>
        <ReferenceField reference='patient' source="patient_id" label="Member ID" link={false}>
          <TextField source="current_eligibility.id_value"/>
        </ReferenceField>
        <ReferenceField reference='patient' source="patient_id" label="Member DOB" link={false}>
          <DateField source="date_of_birth"/>
        </ReferenceField>
        <CMSStatusField label="CMS Status"/>
        <DateField source="max_service_date" label="Last Service Date"/>
      </SapiensFieldsRow>
      <ReferenceManyField reference="audit_claim"
                          target="claim#visit_id"
                          source="visit_id"
                          sort={{field: 'id', order: 'ASC'}}
                          filter={{'audit_id@_eq': auditClaimRecord.audit_id}}
                          pagination={<Pagination/>}
                          label="Visit">
        <SingleFieldList linkType={relatedClaimsLink}>
          <RelatedClaimField current={auditClaimRecord.claim_id}/>
        </SingleFieldList>
      </ReferenceManyField>
      <ReferenceManyField reference="claim_service"
                          target="claim_id"
                          sort={{field: 'srv_frm_dt', order: 'DESC'}}
                          pagination={<Pagination/>}
                          label="Services">
        <Datagrid bulkActionButtons={false}>
          <TextField source="service_type" label="CPT"/>
          <TextField source="service_desc" label="Service"/>
          <ProviderReferenceField/>
          <ServiceOriginField/>
          <DateField source="srv_frm_dt" label="Service date"/>
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  );
};

export const CustomBreadcrumb = (props) => {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{paddingTop: 1, paddingLeft: 2}}>
      <Link to="/audit">
        Audits
      </Link>
      <Link to={`/audit/${record.audit_id}/show`}>
        {`Audit ${record.audit_id}`}
      </Link>
      <Typography>
        {`Claim ${record.claim_id}`}
      </Typography>
    </Breadcrumbs>
  );
};

export const DiagnosisResultField = ({source='result', ...rest}) => {
  const record = useRecordContext();
  if (!record) return null;
  const fieldValue = record[source];
  const found = AuditDiagnosisResultChoices.find((element) => element.id === fieldValue);
  let displayValue;
  if (found === undefined) {
    displayValue = fieldValue;
  } else {
    displayValue = found.name;
  }
  record['displayValue'] = displayValue;
  return <TextField source={'displayValue'}/>;
}
