import {
  BooleanInput,
  DateInput,
  Edit, Labeled,
  NumberInput, ReferenceField,
  SelectInput,
  SimpleForm, SimpleFormIterator, TextField,
  ReferenceManyField,
  Pagination,
  Datagrid,
  DateField,
  Button,
  Link,
  WithRecord,
  BooleanField,
  useNotify,
  useRefresh,
  useRecordContext,
  useListContext,
  usePermissions,
  SingleFieldList,
  ChipField,
  NumberField,
  Toolbar,
  SaveButton,
} from "react-admin";
import {ReferenceManyInput} from "@react-admin/ra-relationships";
import DiagnosisInput from "../claim/DiagnosisInput";
import AuditStatusChipField from "./AuditStatusChipField";
import UserInput from "../user/UserInput";
import {useEffect, useState} from "react";
import CMSStatusField from "../claim/CMSStatusField";
import AuditClaimStatusField from "./AuditClaimStatusField";
import PatientNameReferenceField from "../patient/PatientNameReferenceField";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {delClaimsFromAudit} from "../audit/common";
import {useApolloClient} from "@apollo/client";
import AuditStatusInput from "./AuditStatusInput";
import GatekeeperReferenceField from "../gatekeeper/GatekeeperReferenceField";


const AuditEditToolbar = props => (
  <Toolbar {...props} >
    <SaveButton />
  </Toolbar>
);

export const AuditEditInputsBasicEdit = () => (
  <>
    <UserInput />
    <DateInput source='date_from'/>
    <DateInput source='date_to'/>
    <NumberInput  source='percentage'/>
  </>
);

export const AuditEditInputsBasicShow = () => (
  <>
    <Labeled label='User'>
      <ReferenceField source="user_id" reference="user" link={false}>
        <TextField source="name" />
      </ReferenceField>
    </Labeled>
    <Labeled label='Date from'>
      <DateField source='date_from'/>
    </Labeled>
    <Labeled label='Date to'>
      <DateField source='date_to'/>
    </Labeled>
    <Labeled label='Percentage'>
      <NumberField  source='percentage'/>
    </Labeled>
  </>
);

export const AuditEditInputsFiltersEdit = () => (
  <>
    <BooleanInput source='hcc_only'/>
    <SelectInput source="scope" choices=
      {[
        { id: 'Audited', name: 'Audited' },
        { id: 'Unaudited', name: 'Unaudited' },
        { id: 'Both', name: 'Both' },
      ]}
    />
    <ReferenceManyInput reference='audit_diagnosis_filter' target='audit_id'>
      <SimpleFormIterator inline>
        <DiagnosisInput source="diagnosis_id" reference='diagnosis'/>
      </SimpleFormIterator>
    </ReferenceManyInput>
  </>
);

export const AuditEditInputsFiltersShow = () => (
  <>
    <Labeled label='Hcc only'>
      <BooleanField source='hcc_only'/>
    </Labeled>
    <Labeled label='Scope'>
      <TextField source="scope"/>
    </Labeled>
    <Labeled label='Audit diagnosis filter'>
      <ReferenceManyField reference='audit_diagnosis_filter' target='audit_id'>
        <SingleFieldList linkType={false}>
          <ChipField source="diagnosis_id" size="small" />
        </SingleFieldList>
      </ReferenceManyField>
    </Labeled>
  </>
);

export const AuditEditInputs = () => {
  const record = useRecordContext();
  const { permissions } = usePermissions();
  if (permissions === undefined) return null;

  return (
    <>
      <Labeled label='PCP'>
        <GatekeeperReferenceField />
      </Labeled>
      { permissions.includes('supervisor') ?  <AuditStatusInput/> : <AuditStatusChipField /> }
      { (['new', 'started'].includes(record.status)) ? <AuditEditInputsBasicEdit/> : <AuditEditInputsBasicShow/> }
      { (record.status === 'new') ? <AuditEditInputsFiltersEdit/> : <AuditEditInputsFiltersShow/> }
    </>
  );
};

export const BulkDeleteAuditClaimFromAuditButton = ({related}) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const record = useRecordContext();
  const apolloClient = useApolloClient();
  const [running, setRunning] = useState(false);
  const {selectedIds, onUnselectItems} = useListContext();

  // This button is only active when the audit is not finished
  if (record.status === 'finished') return null;

  useEffect(() => {
    if (running) {
      delClaimsFromAudit(apolloClient, record.id, selectedIds, related)
        .then(() => {
          onUnselectItems();
          refresh();
        })
        .catch((e) => {
          notify(`Error deleting claims`, { type: 'error' });
          console.log(`error when deleting claims`);
          console.log(e);
        })
        .finally(() => {
          setRunning(false);
        });
    }
  }, [running]);

  const delSelected = () => {
    setRunning(true);
  }

  return (
    <>
      <Button
        label={related ? "Remove claims and related from audit" : "Remove claims from audit"}
        disabled={running}
        onClick={delSelected}
      >
        <DeleteIcon />
      </Button>
      <Backdrop
        sx={{ color: '#fff', zIndex:  1000 }}
        open={running}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

const AuditClaimListBulkActionButtons = props => (
  <>
    <BulkDeleteAuditClaimFromAuditButton related={true} {...props} />
    <BulkDeleteAuditClaimFromAuditButton related={false} {...props} />
  </>
);

export const AuditEditClaims = () => {
  const record = useRecordContext();

  return (
    <Labeled>
      <ReferenceManyField
        label="Claims"
        reference="audit_claim"
        source="id"
        target="audit_id"
        sort={{field: 'claim.max_service_date,claim.patient_id,claim_id', order: 'asc,asc,asc'}}
        pagination={<Pagination />}
      >
        { (['new', 'started'].includes(record.status)) ? (
            <Button
            startIcon={<AddIcon />}
            label="Add claims"
            component={Link}
            to='/claim/'
            state={{audit_id: record.id}}
            style={{width: 'fit-content'}}
          />
          ) : null
        }
        <Datagrid
          rowClick={false}
          bulkActionButtons={(['new', 'started'].includes(record.status)) ? <AuditClaimListBulkActionButtons /> : false}
        >
          <ReferenceField link={false} label="Claim Number" reference="claim" source='claim_id'>
            <TextField source="claim_nbr" />
          </ReferenceField>
          <DateField label="Service date" source="claim.max_service_date" />
          <PatientNameReferenceField label="Member name" source='claim.patient_id' link={false}/>
          <ReferenceField link={false} label="Service provider" reference="provider" source="claim.max_service_provider_id">
            <TextField source="name" />
          </ReferenceField>
          <BooleanField label="Manually added" source='is_manually_added'/>
          <WithRecord label="CMS Status" render={record => <CMSStatusField  record={record.claim}/>}/>
          <AuditClaimStatusField label="Audited"/>
        </Datagrid>
      </ReferenceManyField>
    </Labeled>
  );
};

export const AuditEdit = () => (
  <Edit mutationMode='pessimistic'>
    <SimpleForm toolbar={<AuditEditToolbar />}>
      <AuditEditInputs />
      <AuditEditClaims />
    </SimpleForm>
  </Edit>
);

export default AuditEdit;
