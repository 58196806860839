import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceManyField,
  SingleFieldList,
  FunctionField,
  TextInput,
  ChipField, DateInput, ReferenceField, useListContext, useUnselectAll, useDataProvider, Button, useCreate
} from "react-admin";
import PatientInput from "../patient/PatientInput";
import ReportStateInput from "../reconciliationreport/ReportStateInput";
import ProviderPractitionerInput from "../providerpractitioner/ProviderPractitionerInput";
import GatekeeperInput from "../providerpractitioner/GatekeeperInput";
import UserInput from "../user/UserInput";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import PatientNameReferenceField from "../patient/PatientNameReferenceField";
import {Typography} from "@mui/material";
import {useState} from "react";
import AddIcon from "@mui/icons-material/Add";
import {project} from "ramda";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import QueryDiagnosesRelevanceIdInput from "./QueryDiagnosesRelevanceIdInput";


export const BulkAddReconciliationReportQueryToReportButton = () => {
  let { state } = useLocation();
  const navigate = useNavigate();
  const [running, setRunning] = useState(false);
  const {selectedIds, resource } = useListContext();
  const unselectAll = useUnselectAll(resource);
  const dataProvider = useDataProvider();
  const [create, { isLoading, error }] = useCreate();

  const reportId = state?.report_id;
  const patientId = state?.patient_id;
  // This button is only active when the view is opened from a report with the origin report and patient
  if (!(reportId && patientId)) return null;


  const createSelected = async () => {
    setRunning(true);
    for (let id of selectedIds) {
      // Get query infomation to duplicate
      const {data: q} = await dataProvider.getOne('reconciliation_report_query', {id: id});
      // clone to the right report
      let n = {
        "latest_service_date": null,
        "latest_service_provider_id": null,
        "query_kind": q.query_kind,
        "query_text": q.query_text,
        "report_id": reportId,
        "clinical_indicators": {data: project(
          ["clinical_indicator", "value", "date_reported", "provider_id"], q.clinical_indicators)},
        "diagnoses": {data: project(
          ["diagnosis_id", "diagnosis_description", "notes", "relevance_id"], q.diagnoses)},
      }
      let r = await create('reconciliation_report_query', {data:n}, {returnPromise: true});
    }
    setRunning(false);
    unselectAll();
    navigate("", {});  // clear the state
    navigate(`/reconciliation_report/${reportId}/show`)
  };

  return (
    <>
      <Button
        label={`Add queries to report ${reportId} for patient`}
        disabled={running}
        onClick={() => createSelected()}
      >
        <AddIcon />
      </Button>
      <Backdrop
        sx={{ color: '#fff', zIndex:  1000 }}
        open={running}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};



const ReconciliationReportQueryBulkActionButtons = props => (
  <>
    <BulkAddReconciliationReportQueryToReportButton {...props} />
  </>
);


export const ReconciliationReportQueryList = (props) => {
  let { state } = useLocation();
  let {patientId} = useParams();

  const reconciliationReportQueryFilters = [
    <PatientInput label="Patient" source="reconciliation_report#patient_id" alwaysOn/>,
    <ProviderPractitionerInput label="Practitioner" source='reconciliation_report#provider_practitioner_id' alwaysOn/>,
    <ReportStateInput label="State" source="reconciliation_report#state@_in"/>,
    <GatekeeperInput label="PCP" source="reconciliation_report#patient#gatekeeper#id"/>,
    <DateInput label="Next appointment from" source='reconciliation_report#next_appointment_date@_gte'/>,
    <DateInput label="Next appointment to" source='reconciliation_report#next_appointment_date@_lte'/>,
    <DateInput label="Created from" source="reconciliation_report#created_at@_gte"/>,
    <DateInput label="Created to" source="reconciliation_report#created_at@_lte"/>,
    <UserInput label="User" source="reconciliation_report#user_id"/>,
    <TextInput label="Diagnosis" source='diagnoses#diagnosis_description@_ilike,diagnoses#diagnosis_id@_ilike'/>,
    <QueryDiagnosesRelevanceIdInput label='Diagnosis answer' source = 'diagnoses#relevance_id@_in'/>,
  ]

  let filter;
  let patientIdFromState = state?.patient_id;
  let finalPatientId = patientId || patientIdFromState;
  let reportId = state?.report_id;

  if (!finalPatientId) {
    filter = {};
  } else {
    filter = {'reconciliation_report#patient_id': finalPatientId};
  }

  return (
    <>
      {reportId &&  <Typography>Select queries to add to report {reportId} for patient {finalPatientId}</Typography>}
      <List
        exporter={false}
        perPage={25}
        sort={{field: 'created_at', order: 'DESC'}}
        filters={reconciliationReportQueryFilters}
        filter={filter}
        {...props}
      >
        <Datagrid
          bulkActionButtons={<ReconciliationReportQueryBulkActionButtons/>}
          rowClick="edit"
        >
          <ReferenceField label='Patient' reference='reconciliation_report' source='report_id' link={false}>
            <PatientNameReferenceField/>
          </ReferenceField>
          <ReferenceField  label='Appointmnet data' reference='reconciliation_report' source='report_id' link={false}>
            <DateField source="next_appointment_date"/>
          </ReferenceField>
          <ReferenceManyField
            label='Diagnoses'
            reference="reconciliation_report_query_diagnosis"
            target='reconciliation_report_query_id'
          >
            <SingleFieldList linkType={false} sx={{flexDirection: 'column'}}>
              <FunctionField render={record => {
                let answer_statuses = {
                  'nr': 'Not clinically relevant',
                  'na': 'Not answered',
                  'a': 'Accepted',
                  'd': 'Denied',
                  'an': 'Accepted (not documented)'};
                return <div style={{display: 'flex', flexDirection: 'column'}}>
                          <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <Typography component="span" variant="body2">[{record.diagnosis_id}] {record.diagnosis_description}</Typography>
                            <Typography component="span" variant="body2">{answer_statuses[record.relevance_id]}</Typography>
                          </div>
                          <Typography component="span" variant="body2" sx={{marginLeft: '50px', fontWeight: '100', fontStyle: 'italic'}}>{record.notes}</Typography>
                       </div>
              }}/>
            </SingleFieldList>
          </ReferenceManyField>
          <ReferenceManyField
            label='Clinical Indicators'
            reference="reconciliation_report_query_clinical_indicator"
            target='reconciliation_report_query_id'
          >
            <SingleFieldList linkType={false}>
              <FunctionField render={record => {
                let new_record = {text: `[${record.clinical_indicator}] ${record.value === null ? '' : record.value}`};
                return <ChipField size='small' record={new_record} source='text'/>
              }
              }/>
            </SingleFieldList>
          </ReferenceManyField>
          <TextField label='Doc. Improvement' source="query_text"/>
          <DateField label='Service date' source="latest_service_date"/>
          <ReferenceField  label='Service provider' reference='provider' source='latest_service_provider_id' link={false}>
            <TextField source="name"/>
          </ReferenceField>
        </Datagrid>
      </List>
    </>
  )
}
