import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AppointmentList from "./AppointmentList";
import AppointmentEdit from "./AppointmentEdit";

const resource = {
  list: AppointmentList,
  edit: AppointmentEdit,
  icon: CalendarMonthIcon,
};

export default resource;