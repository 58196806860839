import {trim} from "lodash/string";

export const VITE_HASURA_GRAPHQL_URL = import.meta.env.VITE_HASURA_GRAPHQL_URL;

if (VITE_HASURA_GRAPHQL_URL === undefined) {
  console.error(`Env variable VITE_HASURA_GRAPHQL_URL mut be set`);
}

export const VITE_COGNITO_USERPOOL_ID = import.meta.env.VITE_COGNITO_USERPOOL_ID

if (VITE_COGNITO_USERPOOL_ID === undefined) {
  console.error(`Env variable VITE_COGNITO_USERPOOL_ID mut be set`);
}

export const VITE_COGNITO_APP_CLIENT_ID = import.meta.env.VITE_COGNITO_APP_CLIENT_ID

if (VITE_COGNITO_APP_CLIENT_ID === undefined) {
  console.error(`Env variable VITE_COGNITO_APP_CLIENT_ID mut be set`);
}

export const VITE_COGNITO_HOSTED_UI_URL = import.meta.env.VITE_COGNITO_HOSTED_UI_URL

if (VITE_COGNITO_HOSTED_UI_URL === undefined) {
  console.error(`Env variable VITE_COGNITO_HOSTED_UI_URL mut be set`);
}

// QS Dashboard
export const VITE_QS_DASHBOARD_ID_1 = import.meta.env.VITE_QS_DASHBOARD_ID_1

if (VITE_QS_DASHBOARD_ID_1 === undefined) {
  console.error(`Env variable VITE_QS_DASHBOARD_ID_1 mut be set`);
}

export const VITE_QS_DASHBOARD_ID_2 = import.meta.env.VITE_QS_DASHBOARD_ID_2

if (VITE_QS_DASHBOARD_ID_2 === undefined) {
  console.error(`Env variable VITE_QS_DASHBOARD_ID_2 mut be set`);
}

export const VITE_SAPIENS_BI_URL = import.meta.env.VITE_SAPIENS_BI_URL

if (VITE_SAPIENS_BI_URL === undefined) {
  console.error(`Env variable VITE_SAPIENS_BI_URL mut be set`);
}


export const VITE_SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN

if (VITE_SENTRY_DSN === undefined) {
  console.info(`Env variable VITE_SENTRY_DSN is not set. Not reporting anything to Sentry. If this is a local development runtime, this is expected.`);
}

export const VITE_SENTRY_ENVIRONMENT = import.meta.env.VITE_SENTRY_ENVIRONMENT === undefined ? 'local' : import.meta.env.VITE_SENTRY_ENVIRONMENT

export const AWS_COMMIT_ID = import.meta.env.AWS_COMMIT_ID === undefined ? 'local' : import.meta.env.AWS_COMMIT_ID

export const VITE_SAPIENS_BI_SECRET = import.meta.env.VITE_SAPIENS_BI_SECRET

if (VITE_SAPIENS_BI_SECRET === undefined) {
  console.error(`Env variable VITE_SAPIENS_BI_SECRET must be set to be able to access the BI dashboard`);
}

export const VITE_MSO_LOGO_URL = import.meta.env.VITE_MSO_LOGO_URL

if (VITE_MSO_LOGO_URL === undefined) {
  console.info(`Env variable VITE_MSO_LOGO_URL is not set. We will not show any logo on rec_report exports`);
}

const hexColorRegEx = /^#[\da-fA-F]{6}$/g

export const VITE_THEME_SECONDARY_MAIN =
  (import.meta.env.VITE_THEME_SECONDARY_MAIN === undefined ||
    trim(import.meta.env.VITE_THEME_SECONDARY_MAIN).match(hexColorRegEx) === null)
    ? '#001626'
    : trim(import.meta.env.VITE_THEME_SECONDARY_MAIN);

console.debug('### Raw Vite environment VITE_THEME_SECONDARY_MAIN ###', import.meta.env.VITE_THEME_SECONDARY_MAIN);
console.debug('### Exported VITE_THEME_SECONDARY_MAIN value ###', VITE_THEME_SECONDARY_MAIN);
