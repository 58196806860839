import {useState} from "react";
import {Collapse, IconButton, Typography, Divider, Box} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CollapsibleSection = (props) => {
  const {title, children, initialOpen} = props;
  const [open, setOpen] = useState(initialOpen);
  const handleOpen = () => setOpen(!open);
  return (
    <Box>
      <Typography variant="h6" style={{display: 'inline-block', marginLeft: '10px'}}>{title}</Typography>
      <IconButton onClick={handleOpen}>{open ? <ExpandLessIcon/> : <ExpandMoreIcon/>}</IconButton>
      <Collapse in={open}>
        {children}
      </Collapse>
      <Divider />
    </Box>
  )
};

CollapsibleSection.defaultProps = {
  initialOpen: true
}

export default CollapsibleSection;
