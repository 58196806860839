import {
  BooleanField,
  Datagrid,
  DateField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import {useState} from "react";
import {FormControlLabel, Switch} from "@mui/material";
import CMSStatusField from "./CMSStatusField";
import ServiceOriginField from "./ServiceOriginField";
import PatientNameReferenceField from "../patient/PatientNameReferenceField";
import ProviderReferenceField from "../provider/ProviderReferenceField";
import {SapiensFieldsRow} from "../components/SapiensFieldsRow";
import PatientIdReferenceField from "../patient/PatientIdReferenceField";
import DiagnosisDeletedField from "./DiagnosisDeletedField";


export const ClaimShow = () => {
  const [deletedDiag, setDeletedDiag] = useState(false);
  const diagnosisFilter = deletedDiag ? {} : { deleted: false };

  return (
    <Show>
      <SimpleShowLayout>
        <SapiensFieldsRow>
          <TextField source="claim_nbr" label="Claim number"/>
          <PatientIdReferenceField label="Member ID" link={false} />
          <PatientNameReferenceField/>
          <CMSStatusField label="CMS Status"/>
          <DateField source="max_service_date" label="Last Service Date"/>
          <BooleanField source="has_service" label="Service?"/>
        </SapiensFieldsRow>
        <ReferenceManyField reference="claim_service"
                            target="claim_id"
                            sort={{field: 'srv_frm_dt', order: 'DESC'}}
                            pagination={<Pagination/>}
                            label="Services">
          <Datagrid bulkActionButtons={false}>
            <TextField source="service_type" label="CPT"/>
            <TextField source="service_desc" label="Service"/>
            <ProviderReferenceField />
            <ServiceOriginField/>
            <DateField source="srv_frm_dt" label="Service date"/>
          </Datagrid>
        </ReferenceManyField>

        <FormControlLabel
          label='Show deleted'
          control={<Switch checked={deletedDiag}
          onChange={(event, value) => setDeletedDiag(value)}/>}
        />
        <ReferenceManyField reference="claim_diagnosis_complete"
                            target="claim_id"
                            pagination={<Pagination/>}
                            sort={{field: 'diag_code', order: 'ASC'}}
                            label="Diagnoses"
                            filter={diagnosisFilter}
                            >
          <Datagrid bulkActionButtons={false}>
            <>
              <TextField source="diag_code" label="Diagnosis Code"/>
              <DiagnosisDeletedField sx={{marginLeft: 5}}/>
            </>
            <ReferenceField source="diag_code" reference="diagnosis_condition_v24" label="HCC (v24)">
              <TextField source="condition_id" />
            </ReferenceField>
            <ReferenceField source="diag_code" reference="diagnosis_condition_v28" label="HCC (v28)">
              <TextField source="condition_id" />
            </ReferenceField>
            <ReferenceField label="Description" reference="diagnosis" source="diag_code">
              <TextField source="description"/>
            </ReferenceField>

          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
}

export default ClaimShow;
