import {Create, email, SimpleForm, TextInput} from 'react-admin';
import GatekeeperInput from "./GatekeeperInput";

const ProviderPractitionerCreate = () => {

  return (
    <Create redirect="list">
      <SimpleForm>
        <TextInput source="name" />
        <GatekeeperInput source="gatekeeper_id" fullWidth />
        <TextInput source="email" validate={email()}/>
      </SimpleForm>
    </Create>
  );
}


export default ProviderPractitionerCreate;
