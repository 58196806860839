import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  ArrayField,
  SingleFieldList,
  FunctionField,
  ChipField,
  ReferenceField,
  ReferenceArrayField,
  useRedirect
} from "react-admin";
import PatientNameReferenceField from '../patient/PatientNameReferenceField';

export const BillingClaimCmsClaimList = () => {
  const redirect = useRedirect();

  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <ReferenceArrayField reference="claim" source="claim_ids" >
          <SingleFieldList linkType="show" empty={<div>N/A</div>}>
              <ChipField source="claim_nbr" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField reference="billing_claim" source="billing_claim_ids" >
          <SingleFieldList linkType="show" empty={<div>N/A</div>}>
              <ChipField source="claim_id" />
          </SingleFieldList>
        </ReferenceArrayField>
        <DateField source="service_date" />
        <DateField source="min_service_date" />
        <DateField source="max_service_date" />
        <TextField source="billing_claim_days" label="Days" />
        <TextField source="primary_payer" />
        <PatientNameReferenceField />
        <ReferenceField
          source="gk_id"
          reference="gatekeeper"
          label="Gatekeeper"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="npi" />
        <ReferenceArrayField reference="user" source="created_by_ids" >
          <SingleFieldList linkType={false} empty={<div>N/A</div>}>
              <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField reference="user" source="updated_by_ids" >
          <SingleFieldList linkType={false} empty={<div>N/A</div>}>
              <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ArrayField source="unmatched_claim_diagnosis" >
          <SingleFieldList linkType={false} empty={<div>N/A</div>}>
            <FunctionField render={(record) => (
              <ChipField record={{name: record }} source="name" size="small" />
            )} />
          </SingleFieldList>
        </ArrayField>
        <ArrayField source="unmatched_billing_claim_diagnosis" >
          <SingleFieldList linkType={false} empty={<div>N/A</div>}>
            <FunctionField render={(record) => (
              <ChipField record={{name: record }} source="name" size="small" />
            )} />
          </SingleFieldList>
        </ArrayField>
        <ArrayField source="unmatched_claim_service" >
          <SingleFieldList linkType={false} empty={<div>N/A</div>}>
            <FunctionField render={(record) => (
              <ChipField record={{name: record }} source="name" size="small" />
            )} />
          </SingleFieldList>
        </ArrayField>
        <ArrayField source="unmatched_billing_claim_service" >
          <SingleFieldList linkType={false} empty={<div>N/A</div>}>
            <FunctionField render={(record) => (
              <ChipField record={{name: record }} source="name" size="small" />
            )} />
          </SingleFieldList>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
}

export default BillingClaimCmsClaimList;
