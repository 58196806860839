import {SearchInput, List, Datagrid, TextField, ShowButton, EditButton} from "react-admin";

const gatekeeperFilters = [
  <SearchInput source="name" alwaysOn/>
];

const GatekeeperList = () => (
  <List filters={gatekeeperFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="name" />
      <TextField source="tax_id"/>
      <TextField source="npi"/>
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export default GatekeeperList;