import {
  Button,
  useRecordContext,
  useListContext,
} from 'react-admin';
import {Link} from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';


export const BulkUpdateGapDispositionButton = () => {
  const record = useRecordContext();
  const { selectedIds, resource } = useListContext();

  return (
      <Button
          label='Update all'
          component={Link}
          to={`/gap_patient/${record.id}/batch_update`}
          state={{
              resource: resource,
              selectedIds: selectedIds,
              patient_id: record.id
          }}
          children={<EditIcon />}
      />
  );
};
