import ResourceIcon from "@mui/icons-material/HealthAndSafety";
import ProviderPractitionerList from "./ProviderPractionerList";
import ProviderPractitionerEdit from "./ProviderPractitionerEdit";
import ProviderPractitionerCreate from "./ProviderPractitionerCreate";
import ProviderPractitionerShow from "./ProviderPractitionerShow";

const resource = {
  list: ProviderPractitionerList,
  create: ProviderPractitionerCreate,
  edit: ProviderPractitionerEdit,
  show: ProviderPractitionerShow,
  icon: ResourceIcon,

};

export default resource;
