import ClaimIcon from '@mui/icons-material/Assignment';
import BillingClaimShow from "./BillingClaimShow";
import BillingClaimList from "./BillingClaimList";

const resource = {
  list: BillingClaimList,
  show: BillingClaimShow,
  icon: ClaimIcon,
};

export default resource;
