import {
  FileField,
  FileInput,
  ReferenceInput,
  SelectInput
} from 'react-admin';
import { useWatch } from 'react-hook-form'
import {FileClases} from "./fileClasses";
import ClearIcon from "@mui/icons-material/Clear";


const DataFileCreateInputs = () => {
  const fileClassWatch = useWatch({ name: 'file_class' });
  const fc = FileClases[fileClassWatch];
  return (
    <>
      <ReferenceInput
        label="Class"
        source="file_class"
        reference="data_file_class"
        sort={{ field: "description", order: "ASC" }}
      >
        <SelectInput optionText="description" fullWidth />
      </ReferenceInput>
      {fc === undefined || fc.inputs === null ? null : fc.inputs}
      {fc === undefined ? null :
        <FileInput removeIcon={ClearIcon} source="data_file" multiple={false} accept={fc.allowedTypes.join(',')}>
          <FileField source="src" title="title"/>
        </FileInput>
      }
    </>
  );
};

export default DataFileCreateInputs;
