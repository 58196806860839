import {
  Datagrid,
  DateField,
  Pagination,
  TextField,
  ReferenceManyField,
  FunctionField,
} from "react-admin";
import {FormControlLabel, Stack, Switch, Typography} from "@mui/material"
import {useState} from "react";


const PrxPanel = () => {
  return (
    <ReferenceManyField reference="prx_service"
                        target="patient_drug_hash_id"
                        sort={{field: 'service_date', order: 'DESC'}}
                        pagination={<Pagination/>}
                        perPage={10}>
      <Datagrid bulkActionButtons={false}>
        <DateField label="Date" source="service_date"/>
        <FunctionField label='Prescriber' render={record => `${record.prescriber_first_name} ${record.prescriber_last_name}`}/>
        <TextField source="drug_class"/>
        <TextField source="drug_name"/>
        <TextField source="drug_strength"/>
        <TextField source="plan_days_supply"/>
        <TextField source="quantity"/>
      </Datagrid>
    </ReferenceManyField>
  );
};

export const PatientShowPrxTab = () => {
  const [includeOTX, setIncludeOTX] = useState(false);
  const [drugNameSearch, setDrugNameSearch] = useState("");

  let filter = {};

  if(!includeOTX) {
    filter['drug_legal_status@_nin'] = ['', 'OTX'];
  }

  if (drugNameSearch) {
    filter["drug_display_name@_ilike"] = drugNameSearch;
  }

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Typography color="textSecondary" className="RaLabeled-label">
          Drug name<br/>
          <input value={drugNameSearch} onChange={e => setDrugNameSearch(e.target.value)} />
        </Typography>
        <FormControlLabel
          control={<Switch checked={includeOTX} onChange={(event, value) => setIncludeOTX(value)}/>}
          label="Include OTC drugs"
        />
      </Stack>
      <ReferenceManyField
        label="PRX"
        reference="prx_grouped"
        target="patient_id"
        filter={filter}
        sort={{field: 'max_service_date,drug_display_name', order: 'DESC,ASC'}}
        pagination={<Pagination/>}
      >
        <Datagrid bulkActionButtons={false} expand={<PrxPanel/>}>
          <DateField source="max_service_date" label="Last service date"/>
          <TextField label='Drug name' source='drug_display_name'/>
        </Datagrid>
      </ReferenceManyField>
    </>
  );
}

export default PatientShowPrxTab;
