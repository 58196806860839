import DraftsIcon from '@mui/icons-material/Drafts';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import SendIcon from '@mui/icons-material/Send';
import Chip from "@mui/material/Chip";
import {useRecordContext} from "react-admin";

// Ignore label prop, its captured so it does not appear in rest
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ReportStateChipField = ({source, label, chipLabel, ...rest}) => {
  const record = useRecordContext();
  const state = record[source] || record['state'];

  if(state === 'draft'){
    let text = chipLabel || 'Draft';
    return <Chip
      icon={<DraftsIcon />}
      label={text}
      size='small'
      {...rest}
    />;
  } else if(state=== 'sent'){
    let text = chipLabel || 'Sent';
    return <Chip
      icon={<SendIcon />}
      label={text}
      size='small'
      {...rest}
    />;
  } else if (state === 'answered') {
    let text = chipLabel || 'Answered';
    return <Chip
      icon={<QuestionAnswerIcon />}
      label={text}
      size='small'
      color='success'
      {...rest}
    />;
  }
  return null;
}


export default ReportStateChipField
