import {
  Show,
  SimpleShowLayout,
  TextField,
  usePermissions
} from 'react-admin';
import GatekeeperReferenceField from "../gatekeeper/GatekeeperReferenceField";

const ProviderPractitionerShow = () => {
  const { permissions } = usePermissions();
  if (permissions === undefined) return null;

  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="name" />
        <GatekeeperReferenceField />
        <TextField source="email" />
      </SimpleShowLayout>
    </Show>
  );
};

export default ProviderPractitionerShow;
