import {Datagrid, DateField, List, Pagination, TextField} from 'react-admin';
import BillingClaimPatientField from "./BillingClaimPatientField";
import GatekeeperReferenceField from "../gatekeeper/GatekeeperReferenceField";



const BillingClaimList = () => (
  <List
    pagination={<Pagination/>}
    perPage={50}
    sort={{field: 'service_date', order: 'DESC'}} >

    <Datagrid rowClick="show">
      <TextField source="claim_id" />
      <TextField label='Payer' source="primary_payer" />
      <TextField label='Payer issued ID' source="primary_insurance_id" />
      <GatekeeperReferenceField />
      <BillingClaimPatientField label='Patient' />
      <DateField source="service_date" />
      <TextField source="billing_npi" />
      <TextField source="rendering_provider" />
      <DateField label='Date of birth' source="dob" />
    </Datagrid>
  </List>
)

export default BillingClaimList;
