import Icon from '@mui/icons-material/Loupe';
import AuditList from "./AuditList";
import AuditEdit from "./AuditEdit";
import AuditCreate from "./AuditCreate";
import AuditShow from "./AuditShow";

const resource = {
  create: AuditCreate,
  show: AuditShow,
  edit: AuditEdit,
  list: AuditList,
  icon: Icon,
};

export default resource;
