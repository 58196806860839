import {
  Create,
  SimpleForm,
  TextInput,
} from "react-admin";


export const UserEdit = () => {
  return (
    <Create>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="email" />
      </SimpleForm>
    </Create>
  );
}

export default UserEdit;
