import NewIcon from '@mui/icons-material/AddCircle';
import UnknownIcon from '@mui/icons-material/QuestionMark';
import CheckIcon from '@mui/icons-material/Check';
import StartIcon from "@mui/icons-material/NotStarted";
import TimerIcon from "@mui/icons-material/Timer";
import CachedIcon from '@mui/icons-material/Cached';
import Chip from "@mui/material/Chip";
import {Labeled, useRecordContext} from "react-admin";
import {StatusFinished, StatusNew, StatusStarted, StatusStarting, StatusResampling} from "./constants";


export const AuditStatusChipField = ({includeLabel=true, ...rest}) => {
  const record = useRecordContext();
  const status = record['status'];

  let labelText = status;
  let icon = <UnknownIcon/>;

  if(status === StatusNew.id){
    labelText = StatusNew.name
    icon = <NewIcon />;
  } else if(status=== StatusStarting.id) {
    labelText = StatusStarting.name;
    icon = <TimerIcon/>;
  } else if(status=== StatusStarted.id){
    labelText = StatusStarted.name;
    icon = <StartIcon />;
  } else if(status=== StatusResampling.id){
    labelText = StatusResampling.name;
    icon = <CachedIcon />;
  } else if (status === StatusFinished.id) {
    labelText = StatusFinished.name;
    icon = <CheckIcon />;
  }

  let chip = <Chip
    sx={{marginTop: '5px', marginBottom: '5px'}}
    icon={icon}
    label={labelText}
    size='small'
    {...rest}
  />

  if (!includeLabel) {
    return chip;
  } else {
    return <Labeled label='Status'>{chip}</Labeled>;
  }
}

export default AuditStatusChipField;
