import {useListContext} from "react-admin";
import {Typography} from "@mui/material";

const PatientEligibilityListField = () => {
  const {data, isLoading} = useListContext();
  if (isLoading) return null;
  const text = data.map((v) => `${v.date_from} to ${v.date_to}`).join(' | ');
  return (
    <Typography component="span" variant="body2">{text}</Typography>
  )
};

export default PatientEligibilityListField;
