import {ReferenceInput, SelectInput} from "react-admin";


const QueryKindInput = ({source, reference, ...rest}) => {
  source = source || "query_kind"
  reference = reference || "query_kind"
  return (
    <ReferenceInput
      source={source}
      reference={reference}
    >
      <SelectInput
        label="Query type"
        optionText="description"
        {...rest}
      />
    </ReferenceInput>
  );
}

export default QueryKindInput;
