import {
  List,
  Datagrid,
  TextField,
  DateField,
  SelectInput,
  TextInput,
  DateInput,
  useRecordContext,
} from 'react-admin';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import {Tooltip} from "@mui/material";

const fileFilters = [
  <SelectInput label="Bucket name" source="bucket_name" alwaysOn choices={[
    { id: "healthplandata-rma", name: "healthplandata-rma" },
    { id: "healthplandata-fha", name: "healthplandata-fha" },
  ]}/>,
  <SelectInput label="Status" source="status" alwaysOn choices={[
    { id: "new", name: "New" },
    { id: "failed", name: "Failed" },
    { id: "incorporated", name: "Processed" },
  ]}/>,
  <TextInput label="Aws key" source="aws_key" alwaysOn/>,
  <DateInput label="Date from" source="pe_date@_gte" alwaysOn/>,
  <DateInput label="Date to" source="pe_date@_lte" alwaysOn/>,
];


const StatusComponent = (props) => {
  const record = useRecordContext();
  if (!record) return null;
  switch (record.status) {
    case 'incorporated':
      return <Tooltip title={'Processed'}>
               <CheckIcon color='success' fontSize='small'/>
             </Tooltip>;
    case 'failed':
      return <Tooltip title={'Failed'}>
               <ErrorIcon color='error' fontSize='small'/>
             </Tooltip>;
    case 'new':
      return <Tooltip title={'New'}>
               <NewReleasesIcon color='info' fontSize='small'/>
             </Tooltip>;
    default:
      return <p>{record.status}</p>;
  }
};


const FileList = () => {
  return(
    <List
      exporter={false}
      perPage={25}
      sort={{field: 'pe_date', order: 'DESC'}}
      filter={{'status@_neq': 'ignored'}}
      filters={fileFilters}
    >
      <Datagrid bulkActionButtons={false}>
        <DateField label='Period' source="pe_date" />
        <StatusComponent label='Status'/>
        <TextField label="Class" source="file_class" />
        <TextField source="bucket_name" />
        <TextField label='File name' source="aws_key" />
      </Datagrid>
    </List>
  );
}

export default FileList;
