import {useFormContext, useWatch} from "react-hook-form";
import {useState} from "react";
import {TextInput, useGetOne} from "react-admin";
import * as React from "react";

const CustomDiagnosisDescriptionInput = ({source, ...rest}) => {
  if(!source) return null;
  let source_array = source.split(".");
  source_array.pop()
  let diagnosis_id_source = source_array.join('.') + '.diagnosis_id';
  const { setValue, getValues } = useFormContext();
  const [currentDiagnosisId, setCurrentDiagnosisId] = useState(null);
  let diagnosis_description = getValues(source)

  // This is trying to fetch even when diagnosis id is null. how to fix?
  const diagnosis_id_watch = useWatch({ name: diagnosis_id_source });
  const { data, isLoading, error, refetch } = useGetOne(
    'diagnosis',
    {id: diagnosis_id_watch},
    {
      onSuccess: (data) => {
        if(currentDiagnosisId !== data?.id && (!diagnosis_description)) {
          setCurrentDiagnosisId(data?.id)
          setValue(source, data?.description)
        }},
      enabled: !!diagnosis_id_watch,
    });
  return (
    <TextInput
      label='Custom diagnosis description'
      source={source}
      {...rest}
    />
  )
}

export default CustomDiagnosisDescriptionInput;
