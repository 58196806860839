import {Show, SimpleShowLayout, useDataProvider, useGetOne} from "react-admin";
import {Typography} from "@mui/material";
import {useState} from "react";
import {renderPdfDocument} from "./renderPdfDocument";
import { PDFViewer } from '@react-pdf/renderer';
import {useParams} from "react-router-dom";

const ReconciliationReportDev = () => {
  let [running, setRunning] = useState(false);
  let [pdfDocument, setPdfDocument] = useState(null);
  let params = useParams();
  let dataProvider = useDataProvider();

  if (!running && !pdfDocument) {
    setRunning(true);
    dataProvider.getOne('reconciliation_report',{id: params.id}).then((result) => {
      let report = result.data;
      renderPdfDocument(report, false)
        .then((pdfDocument) => {
          setPdfDocument(pdfDocument);
        })
        .catch((e) => {console.error(e);})
        .finally(() => {setRunning(false);});
    });
  }
  return (
    <Show actions={null}>
      <SimpleShowLayout>
        {running ? <Typography>Generating PDF...</Typography> : null}
        {pdfDocument ? <PDFViewer width={1400} height={10000}>{pdfDocument}</PDFViewer> : null}
      </SimpleShowLayout>
    </Show>
  );
};

export default ReconciliationReportDev;
