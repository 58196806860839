import {
  Create,
  SimpleForm,
  useCreate, useNotify,
  useRedirect,
} from 'react-admin';
import {gql} from 'graphql-tag';
import { useApolloClient } from '@apollo/client';

import DataFileCreateInputs from "./DataFileCreateInputs";
import {FileClases} from "./fileClasses";

const DataFileCreate = () => {
  const apolloClient = useApolloClient();
  const [create, { isLoading, error }] = useCreate();
  let redirect = useRedirect();
  const notify = useNotify();

  const validateDataFile = (values) => {
    const errors = {};

    if (!values.file_class) {
      errors.file_class = 'Required';
    }

    const fileClassObject = FileClases[values.file_class];

    if (fileClassObject?.validator) {
      fileClassObject.validator(values, errors);
    }

    if (!values.data_file) {
      errors.data_file = 'Required';
    } else {
      if (!fileClassObject.allowedTypes.includes(values.data_file.rawFile.type)){
        errors.data_file = 'This file extension is not supported';
      }
    }
    return errors;
  };

  const onSubmit = async (data) => {
    const fileContentType = data['data_file'].rawFile.type
    try {
      let resp_hasura = await apolloClient.query({
        query: gql`
              query getFileUploadUrl($values: GetFileUploadUrlInput!) {
                get_file_upload_url(values: $values) {
                  url
                  object_key
                }
              }
            `,
        variables: {
          values: {
            'content_type': fileContentType
          }
        },
        fetchPolicy: 'network-only'
      });

      const requestOptions = {
        method: 'PUT',
        body: data['data_file']['rawFile']
      };
      const resp_aws = await fetch(resp_hasura['data']['get_file_upload_url']['url'], requestOptions);

      const bn_result = resp_aws['url'].match(/https:\/\/([\w-]+).s3.amazonaws.com/);
      const bucket_name = bn_result === null ? null : bn_result[1];

      if (resp_aws['status'] === 200) {
        let body = {
          bucket_name: bucket_name,
          aws_key: resp_hasura['data']['get_file_upload_url']['object_key'],
          status: 'uploaded',
          file_name: data['data_file']['rawFile']['name'],
          file_class: data['file_class'],
          gatekeeper_id: data['gatekeeper_id'],
          office_id: data['office_id'],
          report_date: data['report_date'],
        };
        let response = await create('data_file', {data: body}, {returnPromise: true});
        redirect('list', 'data_file', response.id);
      }
    } catch (e) {
      notify('Error uploading file', {type: 'error'});
      console.error(e);
    }
  };
  return (
    <Create>
      <SimpleForm validate={validateDataFile} onSubmit={onSubmit}>
        <DataFileCreateInputs />
      </SimpleForm>
    </Create>
  );
}

export default DataFileCreate;
