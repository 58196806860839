import {useState} from "react";
import {FormControlLabel, Switch} from "@mui/material";
import PatientConditionsList from "./PatientConditionsList";


export const PatientShowHCCTab = () => {
  const [useV28, setUseV28] = useState(false);
  const model = useV28 ? 'v28' : 'v24';

  return (
      <>
        <FormControlLabel label='Use model v28' control={<Switch checked={useV28} onChange={(event, value) => setUseV28(value)}/>} />
        <PatientConditionsList label="HCC Profile" model={model} historical={true} future={true} statusAsIcon={false} />
      </>
  );
}

export default PatientShowHCCTab;
