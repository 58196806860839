import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useState } from 'react';
import { Title } from 'react-admin';
import { gql } from 'graphql-tag';
import { useApolloClient } from '@apollo/client';
import { VITE_QS_DASHBOARD_ID_2 } from "../config";
import { getQuicksightId } from '../auth/sapiensUser';

const QuicksightReports = () => {
  const apolloClient = useApolloClient();
  const [dashboardUrl, setDashboardUrl] = useState("");

  if (dashboardUrl.length === 0) {
    getQuicksightId().then((quicksight_id) => {
      if (quicksight_id) {
        apolloClient.query({
          query: gql`
                query getDashboardUrl($values: GetDashboardUrlInput!) {
                  get_dashboard_url(values: $values) {
                    url
                    request_id
                  }
                }
              `,
          variables: {
            values: {
              "dashboard_id": VITE_QS_DASHBOARD_ID_2,
              "user_name": quicksight_id
            }
          },
          fetchPolicy: 'network-only'
        }).then((response) => {
          setDashboardUrl(response['data']['get_dashboard_url']['url']);
        });
      }
    });
  }

  return(
    <Card>
      <Title title="Billing productivity report"/>
      <CardContent>
        <iframe
          width="1400"
          height="900"
          src={dashboardUrl}>
        </iframe>
      </CardContent>
    </Card>
  );
}

export default QuicksightReports;
