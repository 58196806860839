import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  ReferenceField,
  TopToolbar,
  EditButton,
  usePermissions
} from "react-admin";

const ContractShowTopToolbar = () => {
  const { permissions } = usePermissions();
  if (permissions === undefined) return null;

  return (
    <TopToolbar>
      { permissions.includes('supervisor') ? <EditButton/> : null }
    </TopToolbar>
  )
}

const ContractShow = () => (
  <Show actions={<ContractShowTopToolbar />}>
    <SimpleShowLayout>
      <ReferenceField source="office_id" reference="office" link="show">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="insurance_provider_id" reference="insurance_provider">
        <TextField source="description" />
      </ReferenceField>
      <TextField source="code"/>
      <DateField source="from"/>
      <DateField source="to"/>
    </SimpleShowLayout>
  </Show>
);

export default ContractShow;