import FactCheckIcon from "@mui/icons-material/FactCheck";
import ReconciliationReportQueryEdit from "./ReconciliationReportQueryEdit";
import ReconciliationReportQueryCreate from "./ReconciliationReportQueryCreate";
import {ReconciliationReportQueryList} from "./ReconciliationReportQueryList";


const resource = {
  list: ReconciliationReportQueryList,
  edit: ReconciliationReportQueryEdit,
  create: ReconciliationReportQueryCreate,
  icon: FactCheckIcon,
  // show: ReconciliationReportQueryShow, disable show view for now
};

export default resource;
