import {AutocompleteInput, ReferenceInput} from "react-admin";

export const OfficeInput = ({source, filter, ...rest}) => {
  /**
   * Input with Autocoplete dropdown to search for one or more Offices. NOTE for filter usage below
   *
   * When using from a React Admin Filter view, you MUST provide the source prop for the filters to work properly.
   */
  source = source || "office_id" ;
  filter = filter || {};
  return (
    <ReferenceInput
      source={source}
      filter={filter}
      reference="office"
    >
      <AutocompleteInput
        optionText="name"
        filterToQuery={(searchText) => ({'name@_ilike': searchText})}
        sx={{minWidth: 300}}
        componentsProps={{
          paper: {
            sx: {
              width: 450
            }
          }
        }}
        {...rest}
      />
    </ReferenceInput>
  )
}

export default OfficeInput;
