import {AutocompleteInput, ReferenceInput} from "react-admin";


const UserInput = ({source, reference, ...rest}) => {
  /**
   * Input with Autocoplete dropdown to search for one user. NOTEs for filter usage below
   *
   * Filters require specifiying the source prop or they won't work properly.
   * Input requires specifiying the source and reference props or it won't work properly.
   * @type {string}
   */
  source = source || 'user_id';
  reference = reference || 'user';
  return (
    <ReferenceInput
      source={source}
      reference={reference}
    >
      <AutocompleteInput
        filterToQuery={(searchText) => ({ 'name@_ilike,email@_ilike': searchText })}
        sx={{minWidth: 300}}
        optionText='name'
        {...rest}
      />
    </ReferenceInput>
  );
}

export default UserInput;
