import {ChipField, useRecordContext} from "react-admin";

const DiagnosisDeletedField = (props) => {
  const {source='deleted'} = props;
  const record = useRecordContext(props);
  console.log('DiagnosisDeletedField:', record, source);
  if (!record) return null;
  let data = {'id': 1};
  let color;
  if (record[source]){
    data.name = 'Deleted';
    color = 'error';
    return (
      <ChipField {...props} size="small" record={data} source="name" color={color}/>
    )
  } else {
    return null;
  }
}

export default DiagnosisDeletedField;
