import {AutocompleteInput, ReferenceInput} from "react-admin";

const ConditionYearInput = (props) => {
  /**
   * Input with autocoplete dropdown to search for condition_years NOTE: When using from a React Admin Filter view,
   * you MUST provide the source prop for the filters to work properly.
   */
  const source = props.source || 'condition_years#condition_year_id@_eq';
  return (
    <ReferenceInput
      label="Condition year"
      source={source}
      reference="condition_year"
    >
      <AutocompleteInput
        label="Condition year"
        // optionText="id"
        optionText={(conditionYear) =>
          conditionYear?.id // the empty choice is { id: '' }
            ? `${conditionYear.id} (${conditionYear.date_from} - ${conditionYear.date_to})`
            : ''
        }
//        filterToQuery={(searchText) => ({ 'name@_ilike,current_eligibility.id_value': searchText })}
        {...props}
      />
    </ReferenceInput>
  );
}

export default ConditionYearInput;
