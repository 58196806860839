import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Title } from 'react-admin';
import {VITE_SAPIENS_BI_SECRET, VITE_SAPIENS_BI_URL} from "../config";

const ReportsBI = () => {
  const dashboardURL = `${VITE_SAPIENS_BI_URL}/?secret=${VITE_SAPIENS_BI_SECRET}`
  return(
      <Card>
        <Title title="Inqora BI"/>
        <CardContent>
          {VITE_SAPIENS_BI_SECRET === undefined ?
            <div>
              <h1>BI secret not set</h1>
              <p>Set the BI secret in the environment variables to be able to access the BI dashboard</p>
            </div> :
            <div className="resizer">
              <iframe
                className={"resized"}
                width="1400"
                height="900"
                src={dashboardURL}>
              </iframe>
            </div>
          }
        </CardContent>
      </Card>
);
}

export default ReportsBI;
