import {ChipField, useRecordContext} from "react-admin";

const ServiceOriginField = () => {
  const record = useRecordContext();
  if (!record) return null;

  return (
    <div>
      {record.clm627 === true &&
        <ChipField size="small" source="name" record={{name: "Claim", id: 1}}/>}
      {record.encntr === true &&
        <ChipField size="small" source="name" record={{name: "Encounter", id: 2}}/>}
    </div>
  );
};
export default ServiceOriginField;
