import SummarizeIcon from '@mui/icons-material/Summarize';
import GapPatientList from "./GapPatientList";
import GapPatientShow from "./GapPatientShow";

const resource = {
  list: GapPatientList,
  show: GapPatientShow,
  icon: SummarizeIcon,
};

export default resource;
