import {SelectInput} from "react-admin";


const InsuranceProviderInput = ({source = 'insurance_provider_id', medicare= false, ...rest}) => {
  /**
   * Select Input to select one insurance provider
   *
   * @type {string}
   */
  let insurance_providers = [
    {id: 'humana', name: 'Humana'},
    {id: 'careplus', name: 'Careplus'},
    {id: 'simply', name: 'Simply'},
    {id: 'devoted', name: 'Devoted'},
    {id: 'cigna', name: 'Cigna'},
    {id: 'aetna', name: 'Aetna'},
    {id: 'united', name: 'United Healthcare'},
    {id: 'bcbs', name: 'Blue Cross Blue Shield'},
    {id: 'medica', name: 'Medica'},
    {id: 'preferredcare', name: 'Preferred Care Partners'},
    {id: 'avmed', name: 'AVMed'},
  ]
  if(medicare){
    insurance_providers.push({id: 'medicare', name: "Medicare"})
  }

  return (
    <SelectInput
      label='Insurance provider'
      source={source} choices={insurance_providers}
      {...rest}
    />
  )
}

export default InsuranceProviderInput;
