import {Button, useGetList, useRecordContext} from "react-admin";
import {Link} from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

const CreateReportWithPatientButton = ({patientId, ...rest}) => {
  const record = useRecordContext();
  const id = patientId || record?.id;

  const { data, total, isLoading, error } = useGetList(
    'reconciliation_report',
    {filter: {patient_id: id, state:'draft'}});

  if (isLoading) return null;
  if (error) return null;
  return (
    <Button label='Create report' component={Link}
            to="/reconciliation_report/create"
            disabled={total > 0}
            state={{record: {patient_id: id}}}
            startIcon={<AddIcon/>}
            {...rest}
    />
  );
}

export default CreateReportWithPatientButton;
