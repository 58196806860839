import SummarizeIcon from '@mui/icons-material/Summarize';
import GapList from "./GapList";
import GapEdit from "./GapEdit";
import GapShow from "./GapShow";

const resource = {
  list: GapList,
  edit: GapEdit,
  show: GapShow,
  icon: SummarizeIcon,
};

export default resource;
