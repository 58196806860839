import {
  Show,
  TextField,
  SimpleShowLayout,
} from "react-admin";


export const UserShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="email" />
      </SimpleShowLayout>
    </Show>
  );
}


export default UserShow;
