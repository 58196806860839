import {ChipField, useRecordContext} from "react-admin";
import { Link } from 'react-router-dom';
import {stringify} from "query-string";
import CheckIcon from "@mui/icons-material/Check";


export const CondyearConditionClaimStatusField = (props) => {
  const record = useRecordContext();
  if (!record) return null;
  if (!props.conditionyear && !props.conditionyear_lt) {
    console.error("Did not specify the conditionyear or conditionyear_lt prop")
  }
  let value = record[props.source]
  let hcc_code = record['condition_id']
  const linkFilter = {
    'patient_id': record.patient_id,
    'diagnoses#diagnosis_condition#condition_id@_eq': hcc_code
  }

  if (props.conditionyear){
    linkFilter['condition_years#condition_year_id@_eq'] = props.conditionyear
  } else {
    linkFilter['condition_years#condition_year_id@_lt'] = props.conditionyear_lt
  }

  let data = {'id': 1};
  let linkTo = {
    pathname: '/claim',
    search: stringify({
      filter: JSON.stringify(linkFilter),
      sort: 'max_service_date',
      order: 'DESC'
    })
  };

  let returnChipElement;
  let returnIconElement;
  switch (value){
    case 40:
      data.name = 'Accepted';
      returnChipElement = <Link to={linkTo} style={{ textDecoration: 'none' }}>
        <ChipField size="small" record={data} source="name" color='success'/></Link>;
      returnIconElement = <Link to={linkTo} style={{ textDecoration: 'none' }}>
        <CheckIcon color='success' fontSize='small'/></Link>;
      break;
    case 30:
      data.name = 'Denied';
      returnChipElement = <Link to={linkTo} style={{ textDecoration: 'none' }}>
        <ChipField size="small" record={data} source="name" color='error'/></Link>;
      break;
    case 25:
    case 26:
      data.name = 'Queried';
      returnChipElement = <Link to={linkTo} style={{ textDecoration: 'none' }}>
        <ChipField size="small" record={data} source="name" color='info'/></Link>;
      break;
    case 20:
      data.name = 'Pending';
      returnChipElement = <Link to={linkTo} style={{ textDecoration: 'none' }}>
        <ChipField size="small" record={data} source="name" color='warning'/></Link>;
      break;
    case 10:
      data.name = 'Open';
      returnChipElement = <ChipField size="small" record={data} source="name" color='primary'/>;
      break;
    default:
      return null;
  }

  return props.statusAsIcon ? returnIconElement : returnChipElement;
}

export default CondyearConditionClaimStatusField;
