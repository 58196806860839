// In theme.js
import { defaultTheme } from "react-admin";
import { createTheme } from '@mui/material/styles';
import merge from "lodash/merge";
import {VITE_THEME_SECONDARY_MAIN} from "./config";

export const theme = createTheme(
  merge({}, defaultTheme, {
    palette: {
      secondary: {
        main: VITE_THEME_SECONDARY_MAIN,
      },
    }
  })
);
