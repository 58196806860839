import {
  Edit,
  SimpleForm,
  Labeled,
  TextInput,
  DateInput,
  ReferenceField,
  TextField,
  Toolbar,
  SaveButton
} from "react-admin";

const ContractEditToolbar = () => {
  return (
    <Toolbar>
      <SaveButton/>
    </Toolbar>
  )
}

const ContractEdit = () => (
  <Edit>
    <SimpleForm toolbar={<ContractEditToolbar/>}>
      <Labeled label="Office">
        <ReferenceField source="office_id" reference="office" link={false}>
          <TextField source="name" />
        </ReferenceField>
      </Labeled>
      <Labeled label="Insurance provider">
        <ReferenceField source="insurance_provider_id" reference="insurance_provider">
          <TextField source="description" />
        </ReferenceField>
      </Labeled>
      <TextInput source="code"/>
      <DateInput source="from"/>
      <DateInput source="to"/>
    </SimpleForm>
  </Edit>
);

export default ContractEdit;