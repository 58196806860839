import {TextField, useRecordContext} from "react-admin";
import PatientNameReferenceField from "../patient/PatientNameReferenceField";


const BillingClaimPatientField = ({label, ...rest}) => {
  const record = useRecordContext();

  return (
    (record.patient_id !== null) ?
      <PatientNameReferenceField label={label} /> :
      <TextField label={label} source="name" />
  )
}

export default BillingClaimPatientField;
