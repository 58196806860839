import {Edit, email, SimpleForm, TextInput} from 'react-admin';
import GatekeeperInput from "./GatekeeperInput";

const ProviderPractitionerEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="name" />
      <GatekeeperInput source="gatekeeper_id" fullWidth />
      <TextInput source="email" validate={email()}/>
    </SimpleForm>
  </Edit>
);

export default ProviderPractitionerEdit;
