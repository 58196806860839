import {
  Create,
  required,
  SimpleForm,
  TextInput
} from 'react-admin';
import {DiagnosisArrayInput} from "../claim/DiagnosisInput";
import QueryKindInput from "./QueryKindInput";

export const QueryCreate = () => (
  <Create
    redirect='list'
  >
    <SimpleForm>
      <QueryKindInput label="Query type" source='kind' validate={required()} resettable={false} />
      <TextInput source="description" fullWidth/>
      <DiagnosisArrayInput source="allowed_diagnoses" reference='diagnosis' fullWidth/>
      <TextInput source="template" fullWidth multiline />
    </SimpleForm>
  </Create>
);

export default QueryCreate;
