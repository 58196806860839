import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  BooleanField,
  NullableBooleanInput,
  SelectInput,
  ReferenceInput,
  TopToolbar,
  FilterButton,
  CreateButton,
  ExportButton,
  downloadCSV,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import GatekeeperInput from "../providerpractitioner/GatekeeperInput";
import PatientInput from "./PatientInput";
import InsuranceProviderInput from "./InsuranceProviderInput";


const patientFilters = [
  <PatientInput label="Patient" source="id" alwaysOn/>,
  <NullableBooleanInput source="is_active" alwaysOn choices={[
    { id: 'true', name: 'Active' },
    { id: 'false', name: 'Inactive' },
  ]}/>,
  <GatekeeperInput label="PCP" source="current_eligibility#gatekeeper_id" alwaysOn/>,
  <InsuranceProviderInput label="Insurance provider" source="current_eligibility#insurance_provider_id" alwaysOn />,
  <ReferenceInput label="LOB" source="current_eligibility#lob_id" reference="line_of_business">
    <SelectInput label="LOB" optionText="description" />
  </ReferenceInput>,
];

const PatientListActions = () => (
  <TopToolbar>
    <FilterButton/>
    <CreateButton/>
    <ExportButton maxResults={-1}/>
  </TopToolbar>
);

const patientExporter = (patients, fetchRelatedRecords) => {
  fetchRelatedRecords(
    patients.map(patient => patient.current_eligibility),
    'gatekeeper_id',
    'gatekeeper'
  ).then(gatekeepers => {
    const patientsExpanded = patients.map(patient => {
      const gatekeeper = gatekeepers[patient.current_eligibility.gatekeeper_id];
      return ({
        ...patient,
        gatekeeper: gatekeeper === undefined ? null : gatekeeper.name,
      });
    });
    const patientsForExport = patientsExpanded.map(patient => {
      const {
        id,
        note,
        hcc_score_half_year,
        hcc_score_current_whole_year,
        hcc_score_previous_whole_year,
        is_created_manually,
        is_deleted,
        current_eligibility,
        ...patientForExport
      } = patient;
      return {
        ...patientForExport,
        id_value: patient.current_eligibility.id_value
      };
    });
    jsonExport(patientsForExport, {
        headers: ['name', 'id_value', 'date_of_birth', 'is_active', 'max_service_date']
    }, (err, csv) => {
        downloadCSV(csv, 'patients');
    });
  });
};

export const PatientList = (props) => (
  <List
    perPage={25}
    actions={<PatientListActions/>}
    exporter={patientExporter}
    filters={patientFilters}
    sort={{field: 'max_service_date', order: 'desc_nulls_last'}}
    filterDefaultValues={{ is_active: true }}
    {...props}
  >
    <Datagrid
      rowClick="show"
    >
      <TextField source="name" emptyText="Not available" />
      <TextField label="Insurance Provider ID" source="current_eligibility.id_value" emptyText="Not available"/>
      <DateField label='Date of birth' source='date_of_birth'/>
      <BooleanField source='is_active' label='Active'/>
      <DateField label="Most recent service" source="max_service_date" emptyText="Not available"/>
      <ReferenceField label="PCP" source='current_eligibility.gatekeeper_id' reference='gatekeeper' link={false}>
        <TextField source='name'/>
      </ReferenceField>
    </Datagrid>
  </List>
);

export default PatientList;
