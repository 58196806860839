import {useRecordContext} from "react-admin";
import {Typography} from "@mui/material";

const ACCEPTED_VARIANTS = ['compact', 'date', 'full'];

const PatientNameTextField = (props) => {
  const record = useRecordContext();
  if (!record) return null;

  const variant = ACCEPTED_VARIANTS.includes(props.variant) ? props.variant : 'compact';
  let text;
  switch (variant) {
    case 'compact':
      text = record.name;
      break;
    case 'date':
      text = `${record.name} (${record.date_of_birth})`;
      break;
    case 'full':
      text = `${record.current_eligibility?.id_value} - ${record.name} (${record.date_of_birth})`;
      break;
    default:
      text = record.name;
  }
  return <Typography
    component="span"
    variant="body2"
  >
    {text}
  </Typography>
}

export default PatientNameTextField;
