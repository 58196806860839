import {
  List,
  Datagrid,
  TextField,
  EmailField,
} from 'react-admin';
import UserInput from "./UserInput";


const userFilters = [
  <UserInput label="User" source="id" alwaysOn/>,
];

export const UserList = (props) => (
  <List
    exporter={false}
    filters={userFilters}
    {...props}
  >
    <Datagrid
      rowClick="show"
    >
      <TextField source="name" emptyText="Not available" />
      <EmailField source="email" emptyText="Not available" />
    </Datagrid>
  </List>
);

export default UserList;
