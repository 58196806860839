import FactCheckIcon from "@mui/icons-material/FactCheck";
import ReconciliationReportShow from "./ReconciliationReportShow";
import ReconciliationReportList from "./ReconciliationReportList";
import ReconciliationReportCreate from "./ReconciliationReportCreate";
import ReconciliationReportEdit from "./ReconciliationReportEdit";

const resource = {
  list: ReconciliationReportList,
  show: ReconciliationReportShow,
  edit: ReconciliationReportEdit,
  create: ReconciliationReportCreate,
  icon: FactCheckIcon,
};

export default resource;
