import {gql} from 'graphql-tag';
import buildHasuraProvider, {buildFields} from "ra-data-hasura";

const extractFieldsFromQuery = (queryAst) => {
  return queryAst.definitions[0].selectionSet.selections;
};

const RECONCILIATION_REPORT_GET_ONE_EXTENDED = gql`
  {
    model
    next_appointment_date
    provider_practitioner {
      name
    }
    gatekeeper {
      name
    }
    patient {
      id
      date_of_birth
      hcc_score_half_year
      hcc_score_current_whole_year
      hcc_score_previous_whole_year
      name
      current_eligibility {
        id_type
        id_value
        gatekeeper_id
        lob_id
      }
      accepted_conditions_2(order_by: {model: asc, condition_id: asc}) {
        model
        condition_id
        description
        grouping_id
        historic_years
        latest_provider {
          name
        }
      }
      eligibilities(order_by: {date_from: asc}) {
        date_from
        date_to
      }
      hedis_measures(order_by: {compliance: asc, measure_id: asc, compliance_date: asc}) {
        measure_id
        compliance
        compliance_date
        non_compliance_reason
        suggested_call_to_action
        measure {
          description
        }
      } 
    }
    queries {
      id
      latest_service_date
      latest_provider {
        name
      }
      query_kind
      query_text
      diagnoses {
        diagnosis_description
        diagnosis_id
      }
      clinical_indicators {
        id
        value
        clinical_indicator
        date_reported
        provider {
          name
        }
      }
    }
  }
`;

const RECONCILIATION_REPORT_QUERY_GET_ONE_EXTENDED = gql`
{
  clinical_indicators {
    clinical_indicator
    date_reported
    id
    value
    provider_id
  }
  diagnoses {
    diagnosis_description
    diagnosis_id
    id
    notes
    relevance_id
    diagnosis {
      description
      id
    }
  }
  latest_service_date
  latest_service_provider_id
  query_kind
  query_text
  report_id
  updated_at
  id
}
`

const AUDIT_CLAIM_EXTENDED = gql`
  {
    audit {
       id
       status
       user_id
    }
    claim {
       id
       max_service_date
       patient_id
       max_service_provider_id
       mao_flag
       visit_id
       claim_nbr
    }
  }
`;

const AUDIT_RESULTS_DETAIL_EXTENDED = gql`
  {
    claim {
      patient {
        name
        current_eligibility {
          id_type
          id_value
        }
      }
    }
    diagnosis {
        description
      }
  }
`;

const GAP_PATIENT_EXTENDED = gql`
  {
    patient {
      name
      date_of_birth
      current_eligibility {
        id_value
        insurance_provider_id
        gatekeeper_id
      }
    }
  }
`;

const GAP_EXTENDED = gql`
  {
    patient {
      name
      date_of_birth
      current_eligibility {
        id_value
        insurance_provider_id
        gatekeeper_id
      }
    }
    source_claim {
      claim_nbr
    }
    latest_claim {
      min_service_date
    }
  }
`;

const PATIENT_EXTENDED = gql`
  {
    current_eligibility {
      id_type
      id_value
      insurance_provider_id
      lob_id
      gatekeeper_id
    }
  }
`;

export const customBuildFields = (type, fetchType) => {
  const resourceName = type.name;
  const defaultFields = buildFields(type, fetchType);

  if (resourceName === 'audit_claim') {
    const relatedEntities = extractFieldsFromQuery(AUDIT_CLAIM_EXTENDED);
    defaultFields.push(...relatedEntities);
  }
  if (resourceName === 'reconciliation_report' && fetchType === 'GET_ONE') {
    const relatedEntities = extractFieldsFromQuery(RECONCILIATION_REPORT_GET_ONE_EXTENDED);
    defaultFields.push(...relatedEntities);
  }
  if (resourceName === 'reconciliation_report_query' && fetchType === 'GET_ONE') {
    const relatedEntities = extractFieldsFromQuery(RECONCILIATION_REPORT_QUERY_GET_ONE_EXTENDED);
    defaultFields.push(...relatedEntities);
  }
  if (resourceName === 'audit_results_detail' && fetchType === 'GET_LIST') {
    const relatedEntities = extractFieldsFromQuery(AUDIT_RESULTS_DETAIL_EXTENDED);
    defaultFields.push(...relatedEntities);
  }
  if (resourceName === 'gap_patient') {
    const relatedEntities = extractFieldsFromQuery(GAP_PATIENT_EXTENDED);
    defaultFields.push(...relatedEntities);
  }
  if (resourceName === 'gap') {
    const relatedEntities = extractFieldsFromQuery(GAP_EXTENDED);
    defaultFields.push(...relatedEntities);
  }
  if (resourceName === 'patient') {
    const relatedEntities = extractFieldsFromQuery(PATIENT_EXTENDED);
    defaultFields.push(...relatedEntities);
  }
  return defaultFields;
};

export const buildDataProvider = async (apolloClient) => {
  let hasuraProvider = await buildHasuraProvider(
    {client: apolloClient },
    {buildFields: customBuildFields });
  const dataProvider = {
    ...hasuraProvider,
    create: async (resource, params) => {
      // console.log('resource', resource);
      // console.log('params', params);
      if (resource === 'reconciliation_report') {
        const p = await  hasuraProvider.getOne('patient', {id: params.data.patient_id});
        if (p.data.current_eligibility?.gatekeeper_id) {
          params.data.gatekeeper_id = p.data.current_eligibility?.gatekeeper_id;
        }
      }
      return hasuraProvider.create(resource, params);
    },
    getList: async (resource, params) => {
      // Searching for lists of patients uses the custom search_patient function
      if (resource === 'patient' && params.filter && 'search' in params.filter) {
        let result = await apolloClient.query({
          variables: {searchText: params.filter.search},
          query: gql`
            query searchPatient($searchText: String) {
              search_patient(limit: 15, args: {search: $searchText}) {
                id
                name
                date_of_birth
                current_eligibility {
                  id_value
                }
              }
            }
          `,
        });
        result = {data: result.data.search_patient, total: result.data.search_patient.length};
        return result;
      }

      // Searching for lists of providers uses the custom search_provider function
      if (resource === 'provider' && params.filter && 'search' in params.filter) {
        let result = await apolloClient.query({
          variables: {searchText: params.filter.search},
          query: gql`
            query searchProvider($searchText: String) {
              search_provider(limit: 15, args: {search: $searchText}) {
                id
                name
                tax_id
              }
            }
          `,
        });
        result = {data: result.data.search_provider, total: result.data.search_provider.length};
        return result;
      }
      return hasuraProvider.getList(resource, params);
    }
  };
  return dataProvider;
};
