import {AutocompleteInput, ReferenceInput} from "react-admin";


const PatientInput = ({source, reference, ...rest}) => {
  /**
   * Input with Autocoplete dropdown to search for one patient. NOTEs for filter usage below
   *
   * Filters require specifiying the source prop or they won't work properly.
   * Input requires specifiying the source and reference props or it won't work properly.
   * @type {string}
   */
  source = source || 'patient_id';
  reference = reference || 'patient';
  return (
    <ReferenceInput
      source={source}
      reference={reference}
      perPage={15}
    >
      <AutocompleteInput
        optionText={(patient) => {
          if (patient?.id){
            if(patient?.is_active) {
              return <b>{`${patient.name} (${patient.current_eligibility?.id_value}) ${patient.date_of_birth}`}</b>
            } else {
              return `${patient.name} [${patient.current_eligibility?.id_value}] ${patient.date_of_birth}`
            }
          } else {
            return ''
          }
        }}
        inputText={(patient) => {
          if(!patient) return '';
          return `${patient.name} - ${patient.current_eligibility?.id_value}`
        }}
        filterToQuery={(searchText) => ({ 'search': searchText })}
        sx={{minWidth: 300}}
        componentsProps={{
          paper: {
            sx: {
              width: 600
            }
          }
        }}
        {...rest}
      />
    </ReferenceInput>
);
}

export default PatientInput;
