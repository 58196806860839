import {FunctionField, ReferenceField, useRecordContext} from "react-admin";
import {StatusFinished} from "./constants";


export const AuditResultInListField = (props) => {
  const record = useRecordContext();
  const status = record['status'];

  if(status === StatusFinished.id) {
    return <ReferenceField reference='audit_results_summary' source="id" target="audit_id" >
              <FunctionField render={record => {
                if (record.total_audited_claim_diagnoses === 0) {
                  return 'N/A';
                }
                return `${(100 * record.total_valid_claim_diagnoses / record.total_audited_claim_diagnoses).toFixed(1)}%`}}
              />
           </ReferenceField>
  } else {
    return null;
  }
}

export default AuditResultInListField;
