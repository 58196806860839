import QueryIcon from '@mui/icons-material/Quiz';
import QueryList from "./QueryList";
import {ShowGuesser} from "react-admin";
import {QueryEdit} from "./QueryEdit";
import QueryCreate from "./QueryCreate";

const resource = {
  show: ShowGuesser,
  edit: QueryEdit,
  create: QueryCreate,
  list: QueryList,
  icon: QueryIcon,
};

export default resource;
