import {SelectInput} from "react-admin";
import {StatusFinished, StatusNew, StatusStarted, StatusStarting, StatusResampling} from "./constants";

const AuditStatusInput = ({source='status', ...rest}) => {
  return <SelectInput source={source} choices=
    {[
      { id: StatusNew.id, name: StatusNew.name },
      { id: StatusStarting.id, name: StatusStarting.name },
      { id: StatusStarted.id, name: StatusStarted.name },
      { id: StatusResampling.id, name: StatusResampling.name },
      { id: StatusFinished.id, name: StatusFinished.name },
    ]}
  />
}

export default AuditStatusInput;
