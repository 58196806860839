import {AutocompleteArrayInput, AutocompleteInput, ReferenceArrayInput, ReferenceInput} from "react-admin";
import PropTypes from "prop-types";


export const DiagnosisInput = ({source, reference, ...rest}) => {
  /**
   * Input with autocoplete dropdown to search for one diagnosis NOTE for filter usage below
   *
   * When using from a React Admin Filter view, you MUST provide the source prop for the filters to work properly.
   */
  return (
    <ReferenceInput
      source={source}
      reference={reference}
      sort={{ field: 'id', order: 'ASC' }}
      enableGetChoices={({q}) => {
        return q && q.length >= 3;
      }}
    >
      <AutocompleteInput
        optionText={(diagnosis) => diagnosis?.id // the empty choice is { id: '' }
          ? `[${diagnosis.id}] ${diagnosis.description}` : ''}
        filterToQuery={(searchText) => ({'description@_ilike,id@_ilike': searchText})}
        isOptionEqualToValue={(option, value) =>  option.id === value.id}  //NOTE: React Admin replaces this with their implementation
        sx={{minWidth: 500}}
        componentsProps={{
          paper: {
            sx: {
              width: 600
            }
          }
        }}
        {...rest}
      />
    </ReferenceInput>);
}

// This does NOT WORK at the moment. Not sure why.
export const DiagnosisArrayInput = ({source, reference, ...rest}) => {
  /**
   * Input with Autocoplete dropdown to search for one or more diagnosis. NOTE for filter usage below
   *
   * When using from a React Admin Filter view, you MUST provide the source prop for the filters to work properly.
   */
  source = source || 'diagnoses#diag_code@_in';
  reference = reference || 'diagnosis';
  return (
    <ReferenceArrayInput
      source={source}
      reference={reference}
    >
      <AutocompleteArrayInput
        optionText={(diagnosis) => diagnosis?.id // the empty choice is { id: '' }
          ? `[${diagnosis.id}] ${diagnosis.description}` : ''}
        filterToQuery={(searchText) => ({'description@_ilike,id@_ilike': searchText})}
        // isOptionEqualToValue={(option, value) => option.id === value.id}   NOTE: React Admin replaces this with their implementation
        sx={{minWidth: 500}}
        {...rest}
      />
    </ReferenceArrayInput>
  );
}

DiagnosisInput.propTypes = {
  source: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
}

export default DiagnosisInput;
