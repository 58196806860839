import {AutocompleteArrayInput} from "react-admin";


const ServiceCategoryInput
  = ({source, ...rest}) => {
  source = source || "service_category@_in"
  return (
    <AutocompleteArrayInput
      label="CMS Status"
      source={source}
      choices={[
        {id: 'md', name: 'MD/Other'},
        {id: 'prx', name: 'PRX'},
        {id: 'lab', name: 'Lab'},
      ]}
      {...rest}
    />
  );
}

export default ServiceCategoryInput
;
