import * as React from 'react';
import {ReferenceField, TextField} from 'react-admin';


const PatientIdReferenceField = ({reference='patient', source='patient_id', link='show', label='Patient ID', ...rest}) => {
  return (
    <ReferenceField
      label={label}
      source={source}
      reference={reference}
      link={link}
      {...rest}
    >
      <TextField source='current_eligibility.id_value'/>
    </ReferenceField>
  )};

export default PatientIdReferenceField;
