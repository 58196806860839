import {ChipField, useRecordContext} from "react-admin";

const CMSStatusField = (props) => {
  let {source} = props;
  const record = useRecordContext(props);
  if (!record) return null;
  let data = {'id': 1};
  let color;
  switch (record[source]){
    case 'A':
      data.name = 'Accepted';
      color = 'success';
      break;
    case 'D':
      data.name = 'Denied';
      color = 'error';
      break;
    default:
      data.name = 'Pending';
      color = 'warning';
      break
  }
  return (
    <ChipField {...props} size="small" record={data} source="name" color={color}/>
  )
}

CMSStatusField.defaultProps = {
  source: 'mao_flag',
};

export default CMSStatusField;
