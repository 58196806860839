import {
  Edit,
  SimpleForm,
  TextField,
  DateField,
  ReferenceField,
  TextInput,
  ReferenceInput,
  SelectInput,
  Toolbar,
  SaveButton,
  required,
  Labeled,
} from 'react-admin';
import PatientNameReferenceField from '../patient/PatientNameReferenceField';
import GatekeeperReferenceField from '../gatekeeper/GatekeeperReferenceField';

const GapEditToolbar = props => (
  <Toolbar {...props} >
    <SaveButton />
  </Toolbar>
);

const GapEdit = () => (
  <Edit redirect='show'>
    <SimpleForm toolbar={<GapEditToolbar />}>
      <Labeled>
        <PatientNameReferenceField label='Member Name' />
      </Labeled>
      <Labeled>
        <GatekeeperReferenceField source='patient.current_eligibility.gatekeeper_id' label='PCP' />
      </Labeled>
      <Labeled>
        <TextField source='patient.current_eligibility.id_value' label='Member ID' />
      </Labeled>
      <Labeled>
        <TextField source='patient.date_of_birth' label='DOB' />
      </Labeled>
      <Labeled>
        <ReferenceField
          reference='gap_status'
          source='status'
          label='Type'
          link={false}
        >
          <TextField source='description' />
        </ReferenceField>
      </Labeled>
      <Labeled>
        <ReferenceField
          reference='claim'
          source='latest_claim_id'
          label='Date'
          link='show'
        >
          <DateField source='min_service_date' />
        </ReferenceField>
      </Labeled>
      <Labeled>
        <ReferenceField
          reference='claim'
          source='source_claim_id'
          label='Source'
          link='show'
        >
          <TextField source='claim_nbr' />
        </ReferenceField>
      </Labeled>
      <Labeled>
        <TextField source='diag_code' label='Diagnosis' />
      </Labeled>
      <Labeled>
        <ReferenceField
          label='HCC v24'
          source='diag_code'
          reference='diagnosis_conditions_agg'
        >
          <TextField source='conditions_v24'/>
        </ReferenceField>
      </Labeled>
      <Labeled>
        <ReferenceField
          label='HCC v28'
          source='diag_code'
          reference='diagnosis_conditions_agg'
        >
          <TextField source='conditions_v28'/>
        </ReferenceField>
      </Labeled>
      <Labeled>
        <ReferenceField
          reference='diagnosis'
          source='diag_code'
          label='Diagnosis Description'
          link={false}
        >
          <TextField source='description' />
        </ReferenceField>
      </Labeled>
      <ReferenceInput
        reference='gap_working_status'
        source='working_status'
        label='Disposition'
        sort={{ field: 'description', 'order': 'ASC' }}
      >
        <SelectInput
          optionText='description'
          label='Disposition'
          validate={required()}
        />
      </ReferenceInput>
      <TextInput fullWidth multiline source='comment' />
    </SimpleForm>
  </Edit>
);

export default GapEdit;
