import {ChipField, useRecordContext} from "react-admin";

const ServiceCategoryField = (props) => {
  const record = useRecordContext();
  if (!record) return null;
  let data = {'id': 1};
  let color;
  switch (record.service_category){
    case 'md':
      data.name = 'MD/Other';
      color = 'success';
      break;
    case 'prx':
      data.name = 'PRX';
      color = 'primary';
      break;
    case 'lab':
      data.name = 'Lab';
      color = 'primary';
      break
  }
  return (
    <ChipField {...props} size="small" record={data} source="name" color={color}/>
  )
}

ServiceCategoryField.defaultProps = {
  source: 'service_category',
};

export default ServiceCategoryField;
