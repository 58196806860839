import {Stack} from "@mui/material";
import {Children, isValidElement} from "react";
import {Labeled} from "react-admin";
import clsx from "clsx";
//import {SimpleShowLayoutClasses} from "ra-ui-materialui/src/detail/SimpleShowLayout";


export const SapiensFieldsRow = (props) => {
  const { children, spacing = 2, ...rest } = props;

  return (
        <Stack
          spacing={spacing}
          direction={'row'}
          {...rest}
        >
          {Children.map(children, field =>
            field && isValidElement(field) ? (
              <Labeled
                key={field.props.source}
                className={clsx(
                  'ra-field',
                  field.props.source &&
                  `ra-field-${field.props.source}`,
                  field.props.className
                )}
              >
                {field}
              </Labeled>
            ) : null
          )}
        </Stack>

  );
};
