import {
  Edit,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton
} from "react-admin";

const OfficeEditToolbar = () => {
  return (
    <Toolbar>
      <SaveButton/>
    </Toolbar>
  )
}

const OfficeEdit = () => (
  <Edit>
    <SimpleForm toolbar={<OfficeEditToolbar/>}>
      <TextInput fullWidth source="name"/>
    </SimpleForm>
  </Edit>
);

export default OfficeEdit;