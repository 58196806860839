import {Button, useRecordContext} from "react-admin";
import {Link} from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as React from "react";

export const BackToReconciliationReportButton = (props) => {
  const record = useRecordContext(props);
  return(<Button
    label="Back to report"
    component={Link}
    to={`/reconciliation_report/${record?.report_id}/show`}
    startIcon={<ArrowBackIcon/>}
  />)
}
