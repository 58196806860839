import * as React from 'react';
import { ReferenceField, TextField, usePermissions } from 'react-admin';


const GatekeeperReferenceField = (props) => {
  const {label, source, reference, link, ...rest} = props;
  const { permissions } = usePermissions();
  const linkG = (link === undefined) ? ((permissions === undefined || !permissions.includes('supervisor')) ? false : 'show') : link;

  return <ReferenceField
    label={label}
    source={source}
    reference={reference}
    link={linkG}
    {...rest}
  >
    <TextField source="name" />
  </ReferenceField>
}

GatekeeperReferenceField.defaultProps = {
  source: 'gatekeeper_id',
  label:'Gatekeeper',
  reference: 'gatekeeper',
};

export default GatekeeperReferenceField;
