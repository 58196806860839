import * as React from 'react';
import {
  Edit, TopToolbar, useGetOne, useGetRecordId,
} from 'react-admin';

import {ReconciliationReportQueryForm} from "./ReconciliationReportQueryForm";
import {BackToReconciliationReportButton} from "./BackToReconciliationReportButton";

const ReconciliationReportQueryEditActions = () => {
  return(
    <TopToolbar>
      <BackToReconciliationReportButton />
    </TopToolbar>
  )
}

export const ReconciliationReportQueryEdit = () => {
  const recordId = useGetRecordId();
  const {data: record, isLoading, error} = useGetOne('reconciliation_report_query', {id: recordId});
  if (isLoading) return null;
  if (error) return <p>ERROR</p>;
  if (!record) return <p>NO RECORD</p>;
  const redirect_url = `/reconciliation_report/${record?.report_id}/show`;
  return (
    <Edit
      redirect={redirect_url}
      // redirect='edit'
      actions={<ReconciliationReportQueryEditActions />}
      mutationMode='optimistic'
    >
      <ReconciliationReportQueryForm />
    </Edit>
  );
}

export default ReconciliationReportQueryEdit;
