import {
  Edit,
  SimpleForm,
  TextInput,
  Labeled,
  TextField,
  Toolbar,
  SaveButton
} from "react-admin";

const GatekeeperEditToolbar = () => {
  return (
    <Toolbar>
      <SaveButton/>
    </Toolbar>
  )
}

const GatekeeperEdit = () => (
  <Edit>
    <SimpleForm toolbar={<GatekeeperEditToolbar/>}>
      <TextInput fullWidth source="name"/>
      <TextInput source="tax_id"/>
      <TextInput source="npi"/>
      <Labeled label="Ehr">
        <TextField source="ehr"/>
      </Labeled>
    </SimpleForm>
  </Edit>
);

export default GatekeeperEdit;