import {
    ApolloClient,
    InMemoryCache,
    createHttpLink,
    ApolloProvider,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import {VITE_HASURA_GRAPHQL_URL} from "../config";
import { getIdToken } from "./sapiensUser";

export default function ApolloGraphqlProvider( {children, }) {

  const authLink = setContext(async (_, {headers}) => {
    let jwtToken = null;
    try {
      const idToken = await getIdToken();
      jwtToken = idToken.getJwtToken();
    } catch (e) {
      console.log('@ApolloGraphqlProvider.authLink. Error when trying to get idToken/JwtToken:',e);
    }

    headers = {...headers};
    if (jwtToken) {
      headers.authorization = `Bearer ${jwtToken}`;
    }

    return {headers};
  });

  const httpLink = createHttpLink({
    fetch,
    uri: VITE_HASURA_GRAPHQL_URL,
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
