
import {
  Datagrid,
  FunctionField,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import {diagnosisConditionsAggFormatter} from "../utils";
import {ClaimShow, CustomBreadcrumb} from "./components";
import AuditClaimStatusField from "./AuditClaimStatusField";


const AuditClaimShow = (props) => {
  return (
    <Show>
      <CustomBreadcrumb/>
      <SimpleShowLayout>
        <ReferenceField label='Auditor' reference='user' source='audit.user_id' link={false}>
          <TextField source='name'/>
        </ReferenceField>
      </SimpleShowLayout>
      <ClaimShow relatedClaimsLink='show'/>
      <SimpleShowLayout>
        <AuditClaimStatusField/>
        <ReferenceManyField reference='audit_claim_diagnosis' target='audit_claim_id' sort={{field: 'kind,diagnosis_id', order: 'ASC,ASC'}}>
          <Datagrid bulkActionButtons={false} rowClick={false}>
            <ReferenceField reference='diagnosis' source="diagnosis_id" label="Diagnosis" link={false}>
              <TextField source="id"/>
            </ReferenceField>
            <ReferenceField reference='diagnosis' source="diagnosis_id" label="Diagnosis " link={false}>
              <TextField source="description"/>
            </ReferenceField>
            <ReferenceField reference="diagnosis_conditions_agg" source="diagnosis_id" label={<div>HCC<br/>v24/v28</div>}>
              <FunctionField render={(record) => diagnosisConditionsAggFormatter(record)}/>
            </ReferenceField>
            <TextField source="result" label="Result"/>
            <TextField source="comment" label="Comment"/>
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};

export default AuditClaimShow;
