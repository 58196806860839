import {
  Datagrid,
  DateField,
  Pagination,
  TextField,
  ReferenceManyField,
  FunctionField, ReferenceField,
} from "react-admin";


export const PatientShowHedisTab = () => {

  return (
    <>
      <ReferenceManyField
        label="Measures"
        reference="patient_hedis_measure"
        target="patient_id"
        sort={{field: 'compliance,measure_id,compliance_date', order: 'ASC,ASC,ASC'}}
        pagination={<Pagination/>}
      >
        <Datagrid bulkActionButtons={false} expand={false}>
          <TextField source='measure_id'/>
          <ReferenceField label='Description' reference='hedis_measure' source='measure_id'>
            <TextField source='description'/>
          </ReferenceField>
          <FunctionField label='Compliance' render={record => record.compliance ? 'Yes' : 'No'} sortBy='compliance'/>
          <DateField source='compliance_date'/>
          <TextField source='non_compliance_reason'/>
          <TextField source='suggested_call_to_action'/>
        </Datagrid>
      </ReferenceManyField>
    </>
  );
}

export default PatientShowHedisTab;
