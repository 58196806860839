import {
  Create,
  SimpleForm,
  useDataProvider,
  useRedirect,
  useNotify,
  useUpdate,
  required
} from 'react-admin';
import {useLocation} from 'react-router-dom';

import UserInput from '../user/UserInput';

const AppointmentBatchAssigment = () => {
  const notify = useNotify();
  const [update] = useUpdate();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  let { state } = useLocation();

  const onSubmit = async (data) => {
    let promises = [];

    for (let id of state.selectedIds) {
        const {data: appointment} = await dataProvider.getOne(state.resource, {id: id});
        const p = update(
            'appointment',
            { id: appointment.id, data: {'assigned_to': data.assigned_to}, previousData: appointment }
        );
        promises.push(p);
    }
    Promise.allSettled(promises)
    .then(() => {
        notify('All appointments asssigned to user', { type: 'success' });
    })
    .catch((e) => {
        notify('Error assigning appointments', { type: 'error' });
        console.log('error when assigning appointment')
        console.log(e)
    })
    .finally(() => {
        redirect('list', 'appointment');
    });
};

  return (
    <Create>
      <SimpleForm onSubmit={onSubmit}>
        <UserInput label="Assign to" source="assigned_to" />
      </SimpleForm>
    </Create>
  );
};

export default AppointmentBatchAssigment;
