export const StatusNew = {id: 'new', name: 'New'};
export const StatusStarting = {id: 'starting', name: 'Starting'};
export const StatusStarted = {id: 'started', name: 'Started'};
export const StatusResampling = {id: 'resampling', name: 'Resampling'};
export const StatusFinished = {id: 'finished', name: 'Finished'};

export const AuditDiagnosisResultChoices = [
  {id: 'VALID', name: 'Valid'},
  {id: 'INVALID_S', name: 'Invalid - HCC supported'},
  {id: 'INVALID_NS', name: 'Invalid - HCC not supported'},
  {id: 'SECOND_LEVEL', name: 'Invalid - Second level review required'},
  {id: 'MISSING', name: 'Missing'},
]
