import DiagnosisReconciliationPDF from "./DiagnosisReconciliacionPDF";
import { pdf } from '@react-pdf/renderer'


export const getPdfBlob = async (reconciliationReport, dev=false) => {
  try {
    let document = await renderPdfDocument(reconciliationReport, dev);
    return pdf(document).toBlob();
  } catch (e) {
    console.log(`error: ${e}`);
    return Promise.reject(e);
  }
}


export const renderPdfDocument = async (reconciliationReport, dev=false) => {
  try {
    return <DiagnosisReconciliationPDF report={reconciliationReport} dev={dev} />;
  } catch (e) {
    console.log(`error: ${e}`);
    return Promise.reject(e);
  }
}
