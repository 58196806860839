import ClaimIcon from '@mui/icons-material/Assignment';
import ClaimList from "./ClaimList";
import ClaimShow from "./ClaimShow";

const resource = {
  list: ClaimList,
  show: ClaimShow,
  icon: ClaimIcon,
};

export default resource;
