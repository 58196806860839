import ContractIcon from '@mui/icons-material/LocalHospital';
import ContractEdit from "./ContractEdit";
import ContractShow from "./ContractShow";

const resource = {
  edit: ContractEdit,
  show: ContractShow,
  icon: ContractIcon,
};

export default resource;
