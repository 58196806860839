import {useRecordContext} from "react-admin";
import {useFormContext} from "react-hook-form";
import {Children, cloneElement, createContext} from "react";

export const ReferenceRecordContext = createContext({});

const FormIteratorField = ({childPropsTransformation, resource, source, children, ...rest}) => {
  const parentRecord = useRecordContext();
  const formContext = useFormContext();
  const child = Children.only(children);
  const referenceRecord = formContext.getValues(source);
  let childProps = {resource:resource, source:`${source}.${child.props.source}`, referenceRecord:referenceRecord}
  if (childPropsTransformation !== undefined) {
    childProps = childPropsTransformation(
      childProps,
      parentRecord,
      referenceRecord)
  }
  return <ReferenceRecordContext.Provider value={referenceRecord}>
           {cloneElement(child, childProps)}
         </ReferenceRecordContext.Provider>
}

export default FormIteratorField;
