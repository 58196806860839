import {useDataProvider} from "react-admin";
import {useState} from "react";

export const canEdit = (state) => {
  return state === 'draft';
}

export const canAnswer = (state) => {
  return state === 'sent';
}

export const canSend = (state) => {
  return state === 'draft';
}

export const canDevolveToDraft = (state) => {
  return state === 'sent';
}

export const canViewAnswer = (state) => {
  return state === 'sent' || state === 'answered';
}

export const canSendByEmail = (report) => {
  const dataProvider = useDataProvider();
  const [provPractitionerHasEmail, setProvPractitionerHasEmail] = useState(false);
  dataProvider.getOne(
    'provider_practitioner',
    {id: report.provider_practitioner_id}
  ).then((data) => {
    const providerPractitioner = data['data'];
    setProvPractitionerHasEmail(providerPractitioner.email !== null);
  });
  return canSend(report.state) && provPractitionerHasEmail;
};
