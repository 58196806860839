import {
  BooleanInput,
  DateInput, DeleteButton,
  Edit, Labeled, ReferenceField, required, SaveButton, SelectInput,
  SimpleForm, TextField, Toolbar, useRecordContext,
} from "react-admin";
import ProviderPractitionerInput from "../providerpractitioner/ProviderPractitionerInput";
import PatientNameReferenceField from "../patient/PatientNameReferenceField";
import {Stack} from "@mui/material";
import {canEdit} from "./domain";
import GatekeeperInput from "../providerpractitioner/GatekeeperInput";


const ReconciliationReportEditToolbar = props => {
  const record = useRecordContext();
  if (!record) return false;
  if (!canEdit(record.state)) return false;

  return (
    <Toolbar {...props} >
      <SaveButton/>
      <DeleteButton/>
    </Toolbar>
  )
}

const ReconciliationReportEditForm = () => {
  const record = useRecordContext();
  const isDisabled = !canEdit(record?.state);
  return (
    <SimpleForm toolbar={<ReconciliationReportEditToolbar/>}>
      <Stack
        direction="row"
        spacing={2}
      >
        <Labeled label='Patient name'>
          <PatientNameReferenceField/>
        </Labeled>
        <Labeled label='Patient ID'>
          <ReferenceField reference={'patient'} source={'patient_id'} link={false}>
            <TextField source='current_eligibility.id_value' disabled={isDisabled}/>
          </ReferenceField>
        </Labeled>
      </Stack>
      <ProviderPractitionerInput fullWidth disabled={isDisabled}/>
      <GatekeeperInput fullWidth/>
      <DateInput
        source='next_appointment_date'
        disabled={isDisabled}
      />
      <BooleanInput source='include_hedis_measures'/>
      <SelectInput
        source='model'
        validate={required()}
        choices={[
          {id: 'v24', name: 'use Model v24'},
          {id: 'v28', name: 'use Model v28'},
        ]}
        disabled={isDisabled}
      />
    </SimpleForm>
  )
}

export const ReconciliationReportEdit = () => (
  <Edit
    resource='reconciliation_report'
    redirect='show'
  >
    <ReconciliationReportEditForm/>
  </Edit>
);

export default ReconciliationReportEdit;
