import {useRecordContext} from "react-admin";


const PatientTitle = () => {
  const record = useRecordContext();
  if (!record) return null;
  return <span>{record.name} [{record.current_eligibility?.id_value}] - {record.date_of_birth}</span>;
}

export default PatientTitle;
