import PatientIcon from '@mui/icons-material/Person';
import PatientList from "./PatientList";
import PatientShow from "./PatientShow";
import PatientEdit from "./PatientEdit";
import PatientCreate from "./PatientCreate";

const resource = {
  create: PatientCreate,
  show: PatientShow,
  edit: PatientEdit,
  list: PatientList,
  icon: PatientIcon,
};

export default resource;
