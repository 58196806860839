import {
  List,
  Pagination,
  Datagrid,
  TextField,
  ReferenceArrayInput,
  SelectArrayInput,
} from 'react-admin';
import PatientInput from '../patient/PatientInput';
import DiagnosisInput from '../claim/DiagnosisInput';
import GatekeeperInput from '../providerpractitioner/GatekeeperInput';
import PatientNameReferenceField from '../patient/PatientNameReferenceField';
import GatekeeperReferenceField from '../gatekeeper/GatekeeperReferenceField';


const gapPatientFilters = [
  <ReferenceArrayInput
    label='Status'
    source='gaps#status'
    reference='gap_status'
    sort={{ field: 'description', order: 'ASC' }}
    alwaysOn
  >
    <SelectArrayInput label='Status' optionText='description' />
  </ReferenceArrayInput>,
  <PatientInput label='Patient' source='id' alwaysOn/>,
  <GatekeeperInput label='PCP' source='patient#current_eligibility#gatekeeper_id'/>,
  <DiagnosisInput source='gaps#diag_code' reference='diagnosis' label='Diagnosis'/>,
  <ReferenceArrayInput
    label='Disposition'
    source='gaps#working_status'
    reference='gap_working_status'
    sort={{ field: 'description', order: 'ASC' }}
  >
    <SelectArrayInput label='Disposition' optionText='description' />
  </ReferenceArrayInput>,
];

const GapPatientList = () => (
  <List
    exporter={false}
    pagination={<Pagination/>}
    perPage={50}
    filter={{
      'year': new Date().getFullYear(),
      'patient#is_active': true
    }}
    filters={gapPatientFilters}
  >
    <Datagrid rowClick='show' bulkActionButtons={false}>
      <TextField source='patient.current_eligibility.id_value' label='Member ID' />
      <PatientNameReferenceField source='id' label='Member Name' />
      <TextField source='patient.date_of_birth' label='DOB' />
      <GatekeeperReferenceField source='patient.current_eligibility.gatekeeper_id' label='PCP' />
      <TextField source='captured' label='Captured' />
      <TextField source='suspect' label='Suspect' />
      <TextField source='dropped' label='Dropped' />
    </Datagrid>
  </List>
);

export default GapPatientList;
