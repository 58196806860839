import {
  Show,
  SimpleShowLayout,
  Datagrid,
  ReferenceManyField,
  ReferenceField,
  TextField,
  BooleanField,
  DateField,
  FunctionField,
  Pagination
} from 'react-admin';
import PatientNameReferenceField from '../patient/PatientNameReferenceField';

const AppointmentShowHistory = () => (
    <Show actions={null}>
      <SimpleShowLayout>
        <ReferenceManyField
          label="History"
          reference="appointment_log"
          source="id"
          target="appointment_id"
          sort={{field: "created_at", order: "DESC"}}
          pagination={<Pagination />}
        >
          <Datagrid rowClick={false} bulkActionButtons={false}>
            <DateField label="Date" source="created_at" />
            <TextField label="Loaded Type" source="appointment_type" />
            <TextField label="Inqora Type" source="type" />
            <TextField label="Loaded Status" source="appointment_status" />
            <TextField label="Inqora Status" source="status" />
            <TextField label="Billed Status" source="billed_status" />
            <TextField label="Custom Note" source="custom_note" />
            <ReferenceField label="Assigned To" reference="user" source="assigned_to" link={false}>
              <TextField source="name" />
            </ReferenceField>
            <BooleanField label="Archived" source="archived" />
            <BooleanField label="Reconciled" source="is_billing_reconciled" />
            <BooleanField label="Warning" source="warning" />
            <PatientNameReferenceField label="Member name" />
            <ReferenceField
              label="Provider"
              reference="provider_practitioner"
              source="provider_practitioner_id"
              link="show"
            >
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField
              label="Report"
              reference="reconciliation_report"
              source="reconciliation_report_id"
              link="show"
            >
              <FunctionField render={(record) => record.next_appointment_date}/>
            </ReferenceField>
            {/*<BooleanField label="" source="did_not_match_insurance" />*/}
            <ReferenceField label="Event by" reference="user" source="user_id" link={false}>
              <TextField source="name" />
            </ReferenceField>
            <TextField source="message" />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
);

export default AppointmentShowHistory;