import {AutocompleteInput, ReferenceInput} from "react-admin";


const ProviderInput = ({source, ...rest}) => {

  return (
    <ReferenceInput source={source} reference="provider" >
      <AutocompleteInput
        optionText="name"
        filterToQuery={(searchText) => ({ 'search': searchText })}
        {...rest}
      />
    </ReferenceInput>
);
}

export default ProviderInput;
