import {AutocompleteArrayInput} from "react-admin";


const CMSStatusInput = ({source, ...rest}) => {
  source = source || "mao_flag@_in"
  return (
    <AutocompleteArrayInput
      label="CMS Status"
      source={source}
      choices={[
        {id: 'A', name: 'Accepted'},
        {id: 'D', name: 'Denied'},
        {id: 'P', name: 'Pending'}, // TODO: This is not working. Pending is actually NULL in the DB
      ]}
      {...rest}
    />
  );
}

export default CMSStatusInput;
