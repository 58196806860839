import * as React from 'react';
import { ReferenceField, TextField } from 'react-admin';


const ClaimReferenceField = (props) => (
  <ReferenceField
    label="Claim"
    source="claim_id"
    reference="claim"
    link="show"
    {...props}
  >
    <TextField source="claim_nbr" />
  </ReferenceField>
);

ClaimReferenceField.defaultProps = {
  source: 'claim_id',
};

export default ClaimReferenceField;
