import {
  Edit,
  IconButtonWithTooltip,
  ReferenceField,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  SimpleFormIteratorContext,
  SimpleShowLayout,
  TextField,
  TextInput,
  Toolbar,
  useDataProvider,
  useRecordContext,
  useRefresh,
  useSimpleFormIteratorItem,
} from "react-admin";
import {useContext, useEffect, useState} from "react";
import {TextField as MuiTextField} from '@mui/material';
import CloseIcon from "@mui/icons-material/RemoveCircleOutline";
import {useFormContext} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {ReferenceManyInput} from "@react-admin/ra-relationships";

import DiagnosisInput from "../claim/DiagnosisInput";
import {getUserId} from "../auth/sapiensUser";
import {ClaimShow, CustomBreadcrumb} from "./components";
import FormIteratorField, {ReferenceRecordContext} from "../components/FormIteratorField";
import {AuditDiagnosisResultChoices} from "./constants";


export const DiagnosisDetailsField = (props) => {
  const dataProvider = useDataProvider();
  const referenceRecord = useContext(ReferenceRecordContext);
  const [diagInfo, setDiagInfo] = useState({condition_v24: '', condition_v28: '', initialized: false});
  useEffect(() => {
    const promises = [
      dataProvider.getList('diagnosis_condition_v24', {filter: {id: referenceRecord.diagnosis_id}}),
      dataProvider.getList('diagnosis_condition_v28', {filter: {id: referenceRecord.diagnosis_id}}),
    ];
    if (diagInfo.initialized === false && referenceRecord.diagnosis_id !== null){
      Promise.allSettled(promises).then(
        (results) => {

          const dI = { condition_v24: 'N/A', condition_v28: 'N/A'};
          const v24Result = results[0];
          const v28Result = results[1];
          if (v24Result.status === 'fulfilled') {
            if (v24Result.value.data.length > 0) {
              dI.condition_v24 = v24Result.value.data[0].condition_id;
            }
          }
          if (v28Result.status === 'fulfilled') {
            if (v28Result.value.data.length > 0) {
              dI.condition_v28 = v28Result.value.data[0].condition_id;
            }
          }
          dI.initialized = true;
          setDiagInfo(dI);
        }
      );
    }
  }, []);
  if (referenceRecord === undefined) return null;
  return (
    <>
      <MuiTextField key={referenceRecord.diagnosis_id + '3'}
                    sx={{width: 80}}
                    value={diagInfo.condition_v24}
                    size="small"
                    label={'HCCv24'}
                    disabled={true}
      />
      <MuiTextField key={referenceRecord.diagnosis_id + '4'}
                    sx={{width: 80}}
                    value={diagInfo.condition_v28}
                    size="small"
                    label={'HCCv28'}
                    disabled={true}
      />
    </>);
};


const CustomRemoveItemButton = (props) => {
  const { remove, ...rest } = useSimpleFormIteratorItem();
  const formContext = useFormContext();
  const iteratorContext = useContext(SimpleFormIteratorContext);
  const tempRecord2 = formContext.getValues(iteratorContext.source)[rest.index];
  const isDisabled = (tempRecord2.kind === 'FROM_CLAIM')
  if (isDisabled) return null;
  return (
    <IconButtonWithTooltip
      label="ra.action.remove"
      size="small"
      onClick={() => remove()}
      color="warning"
      {...props}
    >
      <CloseIcon fontSize="small" />
    </IconButtonWithTooltip>
  );
};


export const MyToolbar = (props) => {
  const refresh = useRefresh();
  return (
    <Toolbar {...props}>
      <SaveButton />
      <SaveButton
        label="Save and stay"
        mutationOptions={{
          onSuccess: () => {
            refresh();
          }}
        }
        type="button"
        variant="text"
      />
    </Toolbar>
  );
}


export const AuditClaimEditLayout = (props) => {
  const [loading, setLoading] = useState(true);
  const record = useRecordContext(props);
  const navigate = useNavigate();

  useEffect(() => {
    getUserId().then((id) => {
      setLoading(false);
      if (record.audit.status !== 'started' || record.audit.user_id !== parseInt(id)) {
        console.log('User not authorized to edit this auditClaim, redirecting to show view');
          navigate(`/audit_claim/${record.id}/show`);
      }
    });
  }, [record]);

  if (loading) return null;

  const claimOrAuditDone = (record.claim.status === 'finished' || record.status === 'audited');

  return (
    <>
      <CustomBreadcrumb/>
      <SimpleShowLayout>
        <ReferenceField label='Auditor' reference='user' source='audit.user_id' link={false}>
          <TextField source='name'/>
        </ReferenceField>
      </SimpleShowLayout>
      <ClaimShow relatedClaimsLink='edit'/>
      <SimpleForm toolbar={<MyToolbar/>}>
        <SelectInput source="status" validate={required()} choices={[
          {id: 'audited', name: 'Audited'},
          {id: 'pending', name: 'Pending'},
        ]}/>
        <ReferenceManyInput reference='audit_claim_diagnosis' target='audit_claim_id'
                            sort={{field: 'kind,diagnosis_id', order: 'ASC,ASC'}}>
          <SimpleFormIterator inline disableClear removeButton={<CustomRemoveItemButton/>}>
            <FormIteratorField
              childPropsTransformation={(childProps, auditClaim, auditClaimDiagnosis) => {
                if (auditClaimDiagnosis === undefined) return childProps;
                childProps.disabled = (claimOrAuditDone || auditClaimDiagnosis.kind === 'FROM_CLAIM');
                return childProps}
              }>
              <DiagnosisInput source='diagnosis_id' reference='diagnosis' />
            </FormIteratorField>
            <FormIteratorField
              childPropsTransformation={(childProps, auditClaim, auditClaimDiagnosis) => {
                childProps.disabled=true;
                return childProps;}
              }>
              <DiagnosisDetailsField/>
            </FormIteratorField>
            <FormIteratorField
              childPropsTransformation={(childProps, auditClaim, auditClaimDiagnosis) => {
                if (auditClaimDiagnosis === undefined) return childProps;
                // eslint-disable-next-line no-prototype-builtins
                if (!auditClaimDiagnosis.hasOwnProperty('id') || auditClaimDiagnosis.kind === 'MANUAL') {
                  childProps.disabled = true;
                  childProps.defaultValue = 'MISSING';
                }
                if (claimOrAuditDone) {
                  childProps.disabled = true;
                }
                return childProps;}
              }>
              <SelectInput sx={{width: 250}} source="result" choices={AuditDiagnosisResultChoices}/>
            </FormIteratorField>
            <FormIteratorField
              childPropsTransformation={(childProps, auditClaim, auditClaimDiagnosis) => {
                childProps.disabled=true;
                childProps.defaultValue='MANUAL';
                return childProps;}
              }>
              <SelectInput sx={{display: 'none'}} source="kind" choices={[
                {id: 'FROM_CLAIM', name: 'From claim'},
                {id: 'MANUAL', name: 'Manual'},
              ]}/>
            </FormIteratorField>
            <FormIteratorField
              childPropsTransformation={(childProps, auditClaim, auditClaimDiagnosis) => {
                if (claimOrAuditDone) {
                  childProps.disabled = true;
                }
                return childProps;}
              }>
              <TextInput source="comment" multiline/>
            </FormIteratorField>
          </SimpleFormIterator>
        </ReferenceManyInput>
      </SimpleForm>
    </>
  );
};


const AuditClaimEdit = (props) => {
  const redirectFx = (resource, id, data) => {
    return `audit/${data.audit_id}/show`;
  }

  const transformFx = (data) => {
    const ts = Math.floor(Date.now() / 1000);
    return {
      ...data,
      front_end_ts: ts
    };
  }

  return (
    <Edit mutationMode="pessimistic" redirect={redirectFx} transform={transformFx}>
      <AuditClaimEditLayout/>
    </Edit>
  );
};

export default AuditClaimEdit;
