import {useRecordContext, useReference} from "react-admin";

const ReconciliationReportTitle = () => {
  const record = useRecordContext();
  const { referenceRecord: patient, isLoading, error } = useReference({reference: 'patient', id: record?.patient_id});
  if (!record) return <span>Reconciliation report</span>;
  if (isLoading) { return <span>Reconciliation report #{record.id}</span> }
  if (error) { return <span>Error</span>; }

  const date = record.created_at instanceof Date ? record.created_at : new Date(record.created_at);
  const dateString = date.toLocaleDateString();

  return <span>Report for {patient.name} created at {dateString}</span>;
}

export default ReconciliationReportTitle;
