import {DateField, useGetList, useRecordContext} from "react-admin";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Tooltip from "@mui/material/Tooltip";


const EnrichedAppointmentDateField = (props) => {
  const { source, ...rest } = props;
  if (source === undefined) throw new Error('The source prop is required for EnrichedAppointmentDateField');
  const record = useRecordContext();
  if (!record) return null;
  const value = record[source];
  const { data, total, isLoading, error } = useGetList(
    'patient_eligibility',
    {
      filter: { patient_id: record.patient_id, 'date_from@_lte': value, 'date_to@_gte': value },
    },
    {enabled: record.patient_id !== null},
  );
  if (isLoading || total === undefined || total > 0) {
    return <DateField source={source} {...rest} />;
  } else {
    return <div sx={{flexDirection: 'row'}}>
      <DateField source={source} {...props}/>
      <Tooltip title="This date is not within the patient's eligibility period">
        <WarningAmberIcon sx={{ color: '#FF7E00', marginLeft: 1}}/>
      </Tooltip>
    </div>;
  }
}

export default EnrichedAppointmentDateField;
