import OfficeIcon from '@mui/icons-material/LocalHospital';
import OfficeEdit from "./OfficeEdit";
import OfficeShow from "./OfficeShow";

const resource = {
  edit: OfficeEdit,
  show: OfficeShow,
  icon: OfficeIcon,
};

export default resource;
