import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  SingleFieldList,
  ChipField,
  ReferenceManyField
} from "react-admin";
import BillingClaimPatientField from "./BillingClaimPatientField";
import GatekeeperReferenceField from "../gatekeeper/GatekeeperReferenceField";

export const BillingClaimShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="claim_id" />
      <TextField label='Payer' source="primary_payer" />
      <TextField label='Payer issued ID' source="primary_insurance_id" />
      <GatekeeperReferenceField />
      <BillingClaimPatientField label='Patient' />
      <DateField source="service_date" />
      <TextField source="billing_npi" />
      <TextField source="rendering_provider" />
      <DateField label='Date of birth' source="dob" />

      <ReferenceManyField
        label="Diagnosis"
        reference="billing_claim_diagnosis"
        source="id"
        target="billing_claim_id"
      >
        <SingleFieldList linkType={false} empty={<div>N/A</div>}>
            <ChipField source="diag_code" />
        </SingleFieldList>
      </ReferenceManyField>
      <ReferenceManyField
        label="Services"
        reference="billing_claim_service"
        source="id"
        target="billing_claim_id"
      >
        <SingleFieldList linkType={false} empty={<div>N/A</div>}>
            <ChipField source="procedure" />
        </SingleFieldList>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);

export default BillingClaimShow;
