import React from 'react';
import {createRoot} from "react-dom/client";

import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";

import App from './App';
import ApolloGraphqlProvider from "./auth/ApolloProvider";
import {VITE_SENTRY_DSN, VITE_SENTRY_ENVIRONMENT, AWS_COMMIT_ID} from "./config";

console.log('VITE_SENTRY_ENVIRONMENT', VITE_SENTRY_ENVIRONMENT);
console.log('AWS_COMMIT_ID', AWS_COMMIT_ID);

Sentry.init({
  dsn: VITE_SENTRY_DSN,
  environment: VITE_SENTRY_ENVIRONMENT,
  release: AWS_COMMIT_ID,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/\w+-sapiens.hasura.app\/v1\/graphql/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById('root');
if (!container) {
  throw new Error('Failed to find the root element');
}
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <ApolloGraphqlProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ApolloGraphqlProvider>
  </BrowserRouter>
)

