import {
  SelectInput,
  ReferenceInput,
  useGetOne
} from 'react-admin';


const ReconciliationReportInputText = ({report}) => {
  const { data: patient } = useGetOne(
    'patient',
    {id: report.patient_id},
    {enabled: !!report.patient_id,}
  );
  const { data: provider } = useGetOne(
    'provider_practitioner',
    {id: report.provider_practitioner_id},
    {enabled: !!report.provider_practitioner_id,}
  );

  const provider_name = (provider === undefined) ? '' : provider?.name;

  return (
    <span>{patient?.name} [{report?.next_appointment_date} {report.state}] {provider_name}</span>
  );
};

const ReconciliationReportInput = ({source, reference, filter, ...rest}) => {
  source = source || 'reconciliation_report_id';
  reference = reference || 'reconciliation_report';
  filter = filter || {};

  return (
    <ReferenceInput
      source={source}
      reference={reference}
      filter={filter}
    >
      <SelectInput
        optionText={(report) => <ReconciliationReportInputText report={report}/>}
        sx={{minWidth: 300}}
        {...rest}
      />
    </ReferenceInput>
  );
}

export default ReconciliationReportInput;
