import {AutocompleteArrayInput} from "react-admin";


const QueryDiagnosesRelevanceIdInput = ({source, ...rest}) => {
  source = source || "relevance_id@_in"
  return (
    <AutocompleteArrayInput
      source={source}
      choices={[
        { id: 'd', name: 'Denied' },
        { id: 'nr', name: 'Not clinically relevant' },
        { id: 'a', name: 'Accepted' },
        { id: 'an', name: 'Accepted (not documented)' },
        { id: 'na', name: 'Not answered' },]}
      {...rest}
    />
  );
}

export default QueryDiagnosesRelevanceIdInput;
