import {useRecordContext} from "react-admin";
import Check from '@mui/icons-material/Check';
import {Tooltip} from '@mui/material'


const AuditClaimStatusIcon = (props) => {
  const record = useRecordContext();
  if (!record) return null;
  switch (record[props.source]){
    case 'pending':
      return null;
    case 'audited':
      return <Tooltip title='Audited'>
               <Check color='success' fontSize='small'/>
             </Tooltip>;
  }
}

AuditClaimStatusIcon.defaultProps = {
  source: 'status',
};

export default AuditClaimStatusIcon;
