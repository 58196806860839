import {
  Datagrid, DateField, Pagination, TextField, ReferenceManyField
} from "react-admin";
import {Stack, Typography} from "@mui/material"
import {useState} from "react";


export const PatientShowLabTab = () => {
  const [descSearch, setDescSearch] = useState("");
  const [codeSearch, setCodeSearch] = useState("");

  let filter = {};
  if (descSearch) {
    filter["description@_ilike"] = descSearch
  }
  if (codeSearch) {
    filter["loinc_code@_ilike"] = codeSearch
  }

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Typography color="textSecondary" className="RaLabeled-label">
          Description<br/>
          <input value={descSearch} onChange={e => setDescSearch(e.target.value)} />
        </Typography>
        <Typography color="textSecondary" className="RaLabeled-label">
          Loinc code<br/>
          <input value={codeSearch} onChange={e => setCodeSearch(e.target.value)} />
        </Typography>
      </Stack>
      <ReferenceManyField
        label="Labs"
        reference="lab_result"
        target="patient_id"
        filter={filter}
        sort={{field: 'service_date,id', order: 'DESC,DESC'}}
        pagination={<Pagination/>}
      >
        <Datagrid
          bulkActionButtons={false}
        >
          <DateField source="service_date" label="Service date"/>
          <TextField source='description'/>
          <TextField source='loinc_code'/>
          <TextField source='result_value'/>
          <TextField source='result_unit'/>
          <TextField source='result_abnormal'/>
          <TextField source='normal_alpha'/>
        </Datagrid>
      </ReferenceManyField>
    </>
  );
}

export default PatientShowLabTab;
