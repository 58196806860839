import {useRecordContext} from "react-admin";
import {upperFirst} from "lodash/string";
import {Typography} from "@mui/material";


const AuditClaimStatusField = (props) => {
  const record = useRecordContext();
  if (!record) return null;
  if (!record[props.source]) return null;
  return <Typography variant='body2'>{upperFirst(record[props.source])}</Typography>
}

AuditClaimStatusField.defaultProps = {
  source: 'status',
};

export default AuditClaimStatusField;
