import {
    Button,
    useDataProvider,
    useRecordContext,
    useListContext,
    useNotify,
    useUpdate
} from 'react-admin';
import {useState} from 'react';
import {Link} from 'react-router-dom';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import TaskIcon from '@mui/icons-material/Task';
import RestorePageIcon from '@mui/icons-material/RestorePage';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';


export const AssignAppointmentButton = ({userId, onSuccess}) => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const [update] = useUpdate();
    const [running, setRunning] = useState(false);

    const assignAppointment = async () => {
        setRunning(true);

        const {data: appointment} = await dataProvider.getOne('appointment', {id: record.id});

        update(
            'appointment',
            { id: appointment.id, data: {'assigned_to': userId}, previousData: appointment }
        )
        .then(() => {
            onSuccess();
        })
        .finally(() => {
            setRunning(false);
        });
    };

    return userId === null ? null : (
        <Button
            label="Assign to me"
            disabled={running}
            onClick={() => assignAppointment()}
        />
    );
}

export const BulkToggleArchiveAppointmentButton = ({archived}) => {
    const { selectedIds, resource } = useListContext();
    const dataProvider = useDataProvider();
    const [update] = useUpdate();
    const notify = useNotify();
    const [running, setRunning] = useState(false);

    const toggleArchiveSelected = async () => {
        let promises = [];
        setRunning(true);

        const action_name = archived ? 'archiv' : 'unarchiv'

        for (let id of selectedIds) {
            const {data: appointment} = await dataProvider.getOne(resource, {id: id});
            const p = update(
                'appointment',
                { id: appointment.id, data: {'archived': archived}, previousData: appointment }
            );
            promises.push(p);
        }
        Promise.allSettled(promises)
        .then(() => {
            notify(`All appointments ${action_name}ed`, { type: 'success' });
        })
        .catch((e) => {
            notify(`Error ${action_name}ing appointments`, { type: 'error' });
            console.log(`error when ${action_name}ing appointment`)
            console.log(e)
        })
        .finally(() => {
            setRunning(false);
        });
    };

    return (
        archived ?
        <Button
            label="Archive all"
            disabled={running}
            onClick={() => toggleArchiveSelected()}
        >
            <ArchiveIcon />
        </Button> :
        <Button
            label="Unarchive all"
            disabled={running}
            onClick={() => toggleArchiveSelected()}
        >
            <UnarchiveIcon />
        </Button>
    );
};

export const BulkArchiveAppointmentButton = () => (
    <BulkToggleArchiveAppointmentButton archived={true} />
);

export const BulkUnarchiveAppointmentButton = () => (
    <BulkToggleArchiveAppointmentButton archived={false} />
);

export const BulkToggleReconcileAppointmentButton = ({reconciled}) => {
    const { selectedIds, resource } = useListContext();
    const dataProvider = useDataProvider();
    const [update] = useUpdate();
    const notify = useNotify();
    const [running, setRunning] = useState(false);

    const toggleReconcileSelected = async () => {
        let promises = [];
        setRunning(true);

        const action_name = reconciled ? 'reconcil' : 'undo reconcil'

        for (let id of selectedIds) {
            const {data: appointment} = await dataProvider.getOne(resource, {id: id});
            const p = update(
                'appointment',
                { id: appointment.id, data: {'is_billing_reconciled': reconciled}, previousData: appointment }
            );
            promises.push(p);
        }
        Promise.allSettled(promises)
        .then(() => {
            notify(`All appointments ${action_name}ed`, { type: 'success' });
        })
        .catch((e) => {
            notify(`Error ${action_name}ing appointments`, { type: 'error' });
            console.log(`error when ${action_name}ing appointment`)
            console.log(e)
        })
        .finally(() => {
            setRunning(false);
        });
    };

    return (
        reconciled ?
        <Button
            label="Reconcile all"
            disabled={running}
            onClick={() => toggleReconcileSelected()}
        >
            <TaskIcon />
        </Button> :
        <Button
            label="Undo reconcile all"
            disabled={running}
            onClick={() => toggleReconcileSelected()}
        >
            <RestorePageIcon />
        </Button>
    );
};

export const BulkReconcileAppointmentButton = () => (
    <BulkToggleReconcileAppointmentButton reconciled={true} />
);

export const BulkUndoReconcileAppointmentButton = () => (
    <BulkToggleReconcileAppointmentButton reconciled={false} />
);

export const BulkAppointmentAssignment = () => {
    const { selectedIds, resource } = useListContext();

    return (
        <Button
            label="Assign all"
            component={Link}
            to="/appointment/assigment"
            state={{
                resource: resource,
                selectedIds: selectedIds
            }}
            children={<AssignmentIndIcon />}
        />
    );
};
