import ClaimIcon from '@mui/icons-material/Assignment';
import BillingClaimCmsClaimList from "./BillingClaimCmsClaimList";
import BillingClaimCmsClaimShow from "./BillingClaimCmsClaimShow";

const resource = {
  list: BillingClaimCmsClaimList,
  show: BillingClaimCmsClaimShow,
  icon: ClaimIcon,
};

export default resource;
