import {
  BooleanInput,
  Create,
  DateInput,
  NumberInput, required,
  SelectInput,
  SimpleForm, SimpleFormIterator,
} from "react-admin";
import GatekeeperInput from "../providerpractitioner/GatekeeperInput";
import {ReferenceManyInput} from "@react-admin/ra-relationships";
import DiagnosisInput from "../claim/DiagnosisInput";

const AuditCreate =  () => {

  return (
    <Create redirect='list'>
      <SimpleForm sx={{width: '350px'}}>
        <GatekeeperInput label='PCP' source='gatekeeper_id' filter={{'tax_id@_is_null': false}} validate={required()} fullWidth/>
        <DateInput source='date_from' validate={required()} fullWidth/>
        <DateInput source='date_to' validate={required()} fullWidth/>
        <BooleanInput  source='hcc_only' validate={required()} fullWidth/>
        <NumberInput  source='percentage' validate={required()} max='100' min='1' step='1' fullWidth/>
        <SelectInput source="scope" validate={required()}  fullWidth choices=
          {[
            { id: 'Audited', name: 'Audited' },
            { id: 'Unaudited', name: 'Unaudited' },
            { id: 'Both', name: 'Both' },
          ]}
        />
        <ReferenceManyInput reference='audit_diagnosis_filter' target='audit_id'>
          <SimpleFormIterator inline>
            <DiagnosisInput source="diagnosis_id" reference='diagnosis'/>
          </SimpleFormIterator>
        </ReferenceManyInput>
      </SimpleForm>
    </Create>
  );
}

export default AuditCreate;
