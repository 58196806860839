import UserList from "./UserList";
import UserShow from "./UserShow";
import UserEdit from "./UserEdit";
import UserCreate from "./UserCreate";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';


const resource = {
  show: UserShow,
  edit: UserEdit,
  list: UserList,
  create: UserCreate,
  icon: AccountCircleIcon,
};

export default resource;
