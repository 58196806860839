import {AutocompleteArrayInput} from "react-admin";


const ReportStateInput = ({source, ...rest}) => {
  source = source || "state@_in"
  return (
    <AutocompleteArrayInput
      source={source}
      choices={[
        { id: 'draft', name: 'Draft' },
        { id: 'sent', name: 'Sent' },
        { id: 'answered', name: 'Answered' },]}
      {...rest}
    />
  );
}

export default ReportStateInput;
